import React, {useState} from 'react'
import { useDispatch, useSelector } from "react-redux";
import { useHistory } from 'react-router-dom';

import styles from './ControlPanel.module.css'
import bin from '../../svg/bin 1.svg'
import { updateClientRelation, deleteUser, fetchClients, fetchClientsEnded } from '../../redux/user';
//import Tooltip from '@material-ui/core/Tooltip';
import Modal from '../Modal'

const TableRowEnded = ({clientsEnded}) => {
  const history = useHistory();
  const dispatch = useDispatch();
  const [ modalIsOpen, setIsOpen ] = useState(false);
  //const [ itemId, setItemId ] = useState();
  const [ userId, setUserId ] = useState();
  const [ firstname, setFirstname ] = useState();
  const [ lastname, setLastname ] = useState();
  const Pro = useSelector(({ user: { pro }}) => pro);

  const confirm = async () => {
    try {
      await Promise.all([
        dispatch(deleteUser({userID: userId})),
        dispatch(fetchClients()),
        dispatch(fetchClientsEnded()),
      ])
      console.log('Done');
    } catch(err) {
      console.log('erroe tableRowEnded: ', err);
    }
    setIsOpen(false);
    console.log('Modal Delete user!!!')
  }

  const cancel = () => {
    setIsOpen(false);
    console.log('Modal Cancel')
  }

  const reactivateUser = async(id) => {
    try {
      await Promise.all([
        dispatch(updateClientRelation({userid: id})),
        dispatch(fetchClients()),
        dispatch(fetchClientsEnded()),
      ])

      history.push(`/patientinformation/${id}?reactive=true`)
    } catch(err) {
      console.log('Error reactivate: ', err)
    }

    console.log('Reactivate Patient: ', id); 
  }

  const delUser = (id, fname, lname) => {
    console.log('Delete Patient: ', id);
    setUserId(id);
    setFirstname(fname);
    setLastname(lname);
    setIsOpen(true);
  }

  // Modal Content
  const userType = Pro ? 'klienten' : 'medarbetaren';
  const label = `Genom att radera ${userType} försvinner ${userType}s uppgifter och kan ej återskapas.`
  const content = <p className={styles.name}>Vill du radera <span style={{fontStyle: "italic"}}>{firstname} {lastname}</span></p>

  return (
    <>
      <Modal
        modalOpen={modalIsOpen}
        label={label}
        content={content}
        onConfirm={confirm}
        onCancel={cancel}
        confirmLabel={'Ja'}
        cancelLabel={'Avbryt'}
        iconColor={'red'}
      />
      {
        clientsEnded
        && clientsEnded.length > 0
        && clientsEnded.map(({ id, firstname, lastname, usercode, screeningDaysLeft }) => {
          let diffTxt = 'Ja';
          if (screeningDaysLeft < 30) diffTxt = `Ja (slut om ${screeningDaysLeft}d)`;

          return (
            <tr key={id}>
              <td>{firstname} {lastname}</td>
              <td>{usercode}</td>
              { Math.sign(screeningDaysLeft) === 1 ? 
                <td>{diffTxt}</td> :
                <td>Nej</td>
              }
              <td className={styles.bin}>
                <button className={styles.btnReactivate} onClick={() => reactivateUser(id)}>Återaktivera</button>
                <img src={bin} onClick={() => delUser(id, firstname, lastname)} alt='Bin' />
              </td>
            </tr>
          )
        })
      }    
    </>
  ); 
}

export default TableRowEnded
