import Tooltip from '@material-ui/core/Tooltip';
import Icon from '@mui/material/Icon';
import { useHistory } from 'react-router-dom';
import signalAbovePpmLimit from '../../images/signal-abovePpmLimit.png';
import signalMissedScheduleItem from '../../images/signal-missedScheduleItem.png';
import signalUnderPpmLimit from '../../images/signal-underPpmLimit.png';
import red from '../../svg/Red.svg';
import styles from './ControlPanel.module.css';
import {PatientTransferButton} from '@components/patient'

const Signals = ({ sig }) => {
  let symbol;

  switch (sig) {
    case 'underPpmLimit':
      symbol = signalUnderPpmLimit;
      break;
    case 'abovePpmLimit':
      symbol = signalAbovePpmLimit;
      break;
    case 'missedScheduleItem':
      symbol = signalMissedScheduleItem;
      break;
    default:
      symbol = red;
  }

  return (
    <>
      <img className={styles.symbol} src={symbol} alt="symbol" />
    </>
  );
};

export const SignalSum = ({ signalSummary }) => {
  if (!signalSummary || signalSummary.length === 0) {
    return <div></div>;
  }

  return (
    <div className={styles.signalSumflexEnd}>
      <p>Summering:</p>
      <div className={styles.signalSum}>
        <SignalSummary signalSummary={signalSummary} />
      </div>
    </div>
  );
};

export const SignalSummary = ({ signalSummary }) => {
  if (!signalSummary || signalSummary.length === 0) return null;

  return (
    <>
      {signalSummary &&
        signalSummary.length > 0 &&
        signalSummary.map(({ count, signalType }, index) => (
          <p key={index} className={styles.signalsCount}>
            {count} <Signals sig={signalType} />
          </p>
        ))}
    </>
  );
};

const UserFeatures = ({ userFeatures }) => {
  if (!userFeatures || userFeatures.length === 0) return null;

  return (
    <>
      {userFeatures &&
        userFeatures.length > 0 &&
        userFeatures.map(({ featureId, featureName }) => (
          <span
            key={featureId}
            className={
              featureName === 'hypertoni' ? styles.hypertoni : styles.alcohol
            }
          >
            {featureName}
          </span>
        ))}
    </>
  );
};

const TableRow = ({ clients, terminateModal }) => {
  const history = useHistory();

  if (!clients || clients.length === 0) return null;

  // Sort based on signalSummary
  const data = Array.from(clients).sort((a, b) => {
    let diff = {
      a: {
        abovePpmLimit: 0,
        missedScheduleItem: 0,
        underPpmLimit: 0
      },
      b: {
        abovePpmLimit: 0,
        missedScheduleItem: 0,
        underPpmLimit: 0
      },
    };

    if (a && a.signalSummary && a.signalSummary.length !== 0) {
      a.signalSummary.map(({ count, signalType }) => {
        diff['a'][signalType] = count;
        return null;
      });
    } else {
      return 1;
    }

    if (b && b.signalSummary && b.signalSummary.length !== 0) {
      b.signalSummary.map(({ count, signalType }) => {
        diff['b'][signalType] = count;
        return null;
      });
    } else {
      return -1;
    }

    if (diff.a.abovePpmLimit !== diff.b.abovePpmLimit) {
      return diff.b.abovePpmLimit - diff.a.abovePpmLimit;
    }

    if (diff.a.missedScheduleItem !== diff.b.missedScheduleItem) {
      return diff.b.missedScheduleItem - diff.a.missedScheduleItem;
    }

    if (diff.a.underPpmLimit !== diff.b.underPpmLimit) {
      return diff.b.underPpmLimit - diff.a.underPpmLimit;
    }

    return 0;
  });

  return data.map(
    ({
      id,
      firstname,
      lastname,
      usercode,
      clientID,
      signalSummary,
      screeningDaysLeft,
      userFeatures,
    }) => {
      const onClick = () => history.push('/patientoversikt/' + id);
      const diffTxt =
        screeningDaysLeft < 30 ? `Ja (slut om ${screeningDaysLeft})` : 'Ja';

      return (
        <tr key={id}>
          <td className="cursor-pointer" onClick={onClick}>
            <p className={styles.nameRow}>{firstname} {lastname}</p>
          </td>
          <td>{usercode}</td>
          <td>{clientID}</td>
          <td>{Math.sign(screeningDaysLeft) === 1 ? diffTxt : 'Nej'}</td>
          <td className={styles.bin}>
            <SignalSummary signalSummary={signalSummary} />
          </td>
          {false && ( // a future feature
            <td className={styles.features}>
              <UserFeatures userFeatures={userFeatures} />
            </td>
          )}
          <td>
            <PatientTransferButton userId={id}></PatientTransferButton>
            <Tooltip title="Avsluta klient">
              <Icon
                baseClassName="material-symbols-outlined"
                className="cursor-pointer font-light text-grey hover:text-blue"
                style={{ fontSize: '24px' }}
                onClick={() => terminateModal({ firstname, id, lastname })}
              >
                cancel
              </Icon>
            </Tooltip>
          </td>
        </tr>
      );
    }
  );
};

export default TableRow;
