import React from 'react'
import styles from './documents.module.css';

const Documents = () => {
    return (
        <div className={styles.container}>
            <h2>Kunskapsbank</h2>
            <div className={styles.txtInfo}>
                <p>Kunskapsbanken är för tillfället under byggnation. Här kommer snart att kunna ta del av
                    värdefull kunskap kring beroendeproblematik i allmänhet och systemet Albus i synnerhet. Till
                    dess tackar vi för tålamodet och hänvisar dig till vår support om du har frågor.</p>
            </div>
        </div>
    )
}

export default Documents
