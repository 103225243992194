import React from 'react';
import { useForm } from 'react-hook-form';
import { useDispatch, useSelector } from 'react-redux';

import { useHistory } from 'react-router-dom';
import { fetchSelf, updateProfile } from '../redux/user';
import styles from './ModuleCSS/Therapistprofile.module.css';

export default function UserProfile() {
  const dispatch = useDispatch();
  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm();
  const history = useHistory();
  const user = useSelector(({ user: { self } }) => self);

  const onSubmit = (data) => {
    dispatch(updateProfile(data))
      .then(() => dispatch(fetchSelf()))
      .then((resp) => console.log(resp))
      .catch((err) => console.log(err));
  };

  if (!user) return null;

  return (
    <div className={styles.container}>
      <h2>Kundprofil</h2>
      <div className={styles.txtInfo}>
        Här kan du ändra uppgifterna som är kopplade till dig som administratör. Kontakta vår <span onClick={() => history.push('/Support')}>support</span> vid frågor.
      </div>
      <div className={styles.profile}>
        <form className={styles.form} onSubmit={handleSubmit(onSubmit)}>
          <div className={styles.firstName}>
            <label>FÖRNAMN</label>
            <input
              type="text"
              {...register('firstname', { required: true, maxLength: 20 })}
              defaultValue={user.firstname}
              placeholder="Förnamn"
            />
            {errors.firstname && errors.firstname.type === 'required' && (
              <span>Du måste fylla i ett förnamn</span>
            )}
            {errors.firstname && errors.firstname.type === 'maxLength' && (
              <span>Förnamnet är för långt!</span>
            )}
          </div>
          <div className={styles.lastName}>
            <label>EFTERNAMN</label>
            <input
              type="text"
              {...register('lastname', { required: true, maxLength: 40 })}
              defaultValue={user.lastname}
              placeholder="Efternamn"
            ></input>
            {errors.lastname && errors.lastname.type === 'required' && (
              <span>Du måste fylla i ett efternamn</span>
            )}
            {errors.lastname && errors.lastname.type === 'maxLength' && (
              <span>Efternamnet är för långt!</span>
            )}
          </div>
          <div className={styles.lastName}>
            <label>MAILADRESS</label>
            <input
              type="email"
              {...register('email', {
                required: 'du måste skriva in din email',
                pattern: {
                  value: /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i,
                  message: 'ogiltig email adress',
                },
              })}
              defaultValue={user.email}
              placeholder="Din mailadress...."
            ></input>
            {errors.email && (
              <span>{errors.email && errors.email.message}</span>
            )}
          </div>
          <p></p>

          <button className={styles.btn} type="submit">
            Spara ändringar
          </button>
        </form>
      </div>
    </div>
  );
}
