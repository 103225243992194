import { createAction } from '@reduxjs/toolkit';

export const createTransfer = createAction(
  'user-transfer/create-transfer',
  (data) => ({
    type: 'createTransfer',
    payload: {
      request: {
        url: '/user-transfer/create-transfer',
        method: 'POST',
        data,
      },
    },
  })
);

export const updateTransferStatus = createAction(
  'user-transfer/update-transfer-status',
  (data) => ({
    type: 'updateTransferStatus',
    payload: {
      request: {
        url: '/user-transfer/update-transfer-status',
        method: 'PUT',
        data,
      },
    },
  })
);

export const getTherapistTransfer = createAction(
  'user-transfer/therapist-transfer',
  () => ({
    type: 'getTherapistTransfer',
    payload: {
      request: {
        url: '/user-transfer/therapist-transfer',
        method: 'GET',
      },
    },
  })
);
