import albusAktiverings from '@images/albus-aktiverings-smsv.1.0.001.png';
import { makeStyles } from '@material-ui/core/styles';
import Tooltip from '@material-ui/core/Tooltip';
import InfoOutlinedIcon from '@material-ui/icons/InfoOutlined';
import { useEffect, useState } from 'react';
import 'react-datepicker/dist/react-datepicker.css';
import { useDispatch, useSelector } from 'react-redux';
import {
  NavLink as Link,
  Route,
  Switch,
  useHistory,
  useLocation,
  useRouteMatch
} from 'react-router-dom';
import { newSchedule, sendRegSMS } from '../../redux/user';
import Schedules from '../schedule/Schedules';
import {
  newScheduleData,
  scheduleData_3_items as scheduleData
} from '../schedule/template';
import { setExisting } from '../schedule/utils';
import styles from './AddPatient.module.css';
import StepOne from './StepOne';

import { PatientStepper } from './PatientStepper';

const useStyles = makeStyles((theme) => ({
  small: {
    width: theme.spacing(3),
    height: theme.spacing(3),
  },
  large: {
    width: theme.spacing(7),
    height: theme.spacing(7),
    backgroundColor: 'white',
    color: 'black',
    border: '1px solid ',
  },
}));

const StepTwo = ({ msisdn, newUserId, url }) => {
  const dispatch = useDispatch();
  const history = useHistory();

  const sendSMS = () => {
    console.log('sendSMS ' + newUserId);
    dispatch(sendRegSMS({ userID: newUserId, message: 'Test sms message' }))
      .then(alert(`Ett sms har nu skickats till ${msisdn}`))
      .then(() => {
        history.push(`${url}/schedule`);
      })
      .catch(console.log);
  };

  return (
    <div>
      <div className="mt-5 mb-16">
        <p>Steg 2 - Skicka ett aktiverings-SMS via Albus.</p>
      </div>
      <div className={styles.flexnrSms}>
        <div>
          <div className={styles.msisdn}>
            <p>KLIENTENS TELEFONNUMMER</p>
            <div>{msisdn}</div>
          </div>
          <button className={styles.btn} onClick={sendSMS}>
            Skicka SMS & gå vidare
          </button>
          <Link className={styles.btn} to={`${url}/schedule`}>
            Gå vidare utan att skicka SMS
          </Link>
        </div>
        <div className={styles.smsImg}>
          <img src={albusAktiverings} alt="SMS text" width="346" />
        </div>
      </div>
    </div>
  );
};

const StepFour = ({ Pro }) => {
  const history = useHistory();

  return (
    <div className={styles.stepFour}>
      <h2 className={styles.conH2}>Du har nu aktiverat en ny klient!</h2>
      <p>
        Du kan ändra klientinställningar och schema när som helst. Tveka inte
        att kontakta vår
        <span onClick={() => history.push('/Support')}>support</span> om du
        behöver hjälp.
      </p>
      <p>
        Kom ihåg att din klient behöver en alkomätare för att kunna utföra sina
        utandningstester.
      </p>
      <Link className={styles.btnF} to="/shop">
        Beställ alkomätare
      </Link>
      <Link className={styles.btnF} to="/controlpanel/activepatient">
        Till klientöversikt
      </Link>
    </div>
  );
};

const Sched = ({ newUserId, Pro, url }) => {
  const [schedules, setSchedules] = useState([]);
  const [data, setData] = useState({}); // Kommer innehålla data som ska sparas
  const dispatch = useDispatch();
  const history = useHistory();

  const set = (opts) => setExisting({ setData, ...opts }, data);

  const create = () => {
    console.log('Create', data);

    const schedule = data[0];
    const { start, stop, create, sname } = schedule;

    const payload = {
      scheduleStart: start,
      scheduleEnd: stop,
      scheduleUserId: newUserId,
      scheduleName: sname,
      scheduleItems: create,
    };

    dispatch(newSchedule(payload))
      .then((res) => {
        console.log(res);
        const {
          payload: {
            data: { scheduleId },
          },
        } = res;

        console.log({ scheduleId });
        history.push(`${url}/stepfour`);
      })
      .catch((err) => {
        console.log(err);
      });
  };

  useEffect(() => {
    setSchedules(newScheduleData());
    setData(scheduleData());
  }, []);

  if (!schedules || schedules.length === 0) return null;

  return (
    <>
      <div className="mt-5 mb-8 inline-flex">
        <p>Steg 3 - Skapa ett första blåsschema (eller gõr det senare). </p>
        <Tooltip
          title={
            <div>
              <p>
                Det är under schemats tillfällen som klienten ska genomföra sina
                blås. Klienten får en påminnelse via SMS 30 och 15 minuter innan
                ett blåstillfälle tar slut.
              </p>
              <p>Du kan även skapa eller redigera scheman senare.</p>
            </div>
          }
        >
          <InfoOutlinedIcon style={{ color: 'blue' }} />
        </Tooltip>
      </div>
      <Schedules schedules={schedules} set={set} data={data} slug={'create'} />
      <button className={styles.btn} onClick={create}>
        Spara & aktivera
      </button>
      <Link className={styles.btn} to={`${url}/stepfour`}>
        Hoppa över
      </Link>
    </>
  );
};

function AddPatient() {
  const [msisdn, setMsisdn] = useState('1234');
  const [newUserId, setNewUserId] = useState(49);
  const Pro = useSelector(({ user: { pro } }) => pro);

  const location = useLocation();
  let { path, url } = useRouteMatch();
  let step = 0;
  switch (location.pathname) {
    case `${path}/stepone`:
      step = 1;
      break;
    case `${path}/steptwo`:
      step = 2;
      break;
    case `${path}/schedule`:
      step = 3;
      break;
    default:
      step = 0;
  }

  return (
    <div className={styles.container}>
      {!!step && <PatientStepper activeStep={step}></PatientStepper>}
      <Switch>
        <Route path={`${path}/stepone`}>
          <StepOne
            setMsisdn={setMsisdn}
            setNewUserId={setNewUserId}
            Pro={Pro}
            url={url}
          />
        </Route>
        <Route path={`${path}/steptwo`}>
          <StepTwo Pro={Pro} msisdn={msisdn} newUserId={newUserId} url={url} />
        </Route>
        <Route path={`${path}/schedule`}>
          <Sched Pro={Pro} newUserId={newUserId} url={url} />
        </Route>
        <Route path={`${path}/stepfour`}>
          <StepFour Pro={Pro} />
        </Route>
      </Switch>
    </div>
  );
}

export default AddPatient;
