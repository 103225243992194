import React, { useState, useEffect } from 'react';
import moment from 'moment';

import styles from './Schedule.module.css'

import Schedules from './Schedules';
import { setExisting } from './utils';

const initialState = {};

const Overview = ({ update, refresh, daysLeft, schedules, clientID }) => {
  const [data, setData] = useState(initialState); // Kommer innehålla data som ska sparas

  const set = (opts) => setExisting({ setData, ...opts }, data);

  // if (!schedules || schedules.length === 0) return null;

  const scheduleData = schedules.filter(({ scheduleStart: start, scheduleEnd: stop }) => (
    moment().isBetween(start, stop, 'days', true)
  ));

  // eslint-disable-next-line react-hooks/exhaustive-deps
  useEffect(refresh, []);

  return (
    <div className={styles.messageBox}>
      <Schedules
        schedules={scheduleData}
        set={set}
        data={data}
        slug={'overview'}
        disableInput
        update={update}
        refresh={refresh}
        userId={clientID}
      />
    </div>
  );
};

export default Overview;
