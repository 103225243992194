import { createAction, createSelector, createSlice } from '@reduxjs/toolkit';

export const patientSlice = createSlice({
  name: 'patient',
  initialState: {
    loading: false,
    signals: {
      nr: 0,
      signalId: null,
      signals: [],
      signalSummary: [],
    },
    exportSignals: null,
    breth: [],
    breathCount: 0,
    schedules: [],
  },
  reducers: {
    getSignals: (state, { payload }) => {
      state.loading = true;
    },
    getSignals_SUCCESS: (state, { payload }) => {
      const { nr, signals, signalSummary } = payload.data;

      state.loading = false;
      state.signals = {
        nr,
        signalId: signals?.[0]?.signalId,
        signals: signals,
        signalSummary,
      };
    },
    getbreathalizerResults_SUCCESS: (state, { payload }) => {
      const { BreathalizerResult, breathalizerResults } = payload.data;

      state.breth = BreathalizerResult;
      state.breathCount = breathalizerResults;
    },
    getSchedule_SUCCESS: (state, { payload }) => {
      const { schedules } = payload.data;
      state.schedules = schedules;
    },
    exportSignals: (state, { payload }) => {
      state.exportSignals = null;
    },
    exportSignals_SUCCESS: (state, { payload }) => {
      const { nr, signals, signalSummary } = payload.data;

      state.exportSignals = {
        nr,
        signals,
        signalSummary,
      };
    },
  },
});

// export const { newPatient } = patientSlice.actions;

export default patientSlice.reducer;

// Selector
const patientSelector = (state) => state.patient;

export const signalsSelector = createSelector(
  patientSelector,
  ({ signals }) => signals
);

export const exportSignalsSelector = createSelector(
  patientSelector,
  ({ exportSignals }) => exportSignals
);

export const breathCountSelector = createSelector(
  patientSelector,
  ({ breathCount }) => breathCount
);

export const brethSelector = createSelector(
  patientSelector,
  ({ breth }) => breth
);

export const loadingSelector = createSelector(
  patientSelector,
  ({ loading }) => loading
);

export const schedulesSelector = createSelector(
  patientSelector,
  ({ schedules }) => schedules
);

export const getSignals = createAction('patient/getSignals', (params) => ({
  type: 'getSignals',
  payload: {
    request: {
      url: '/signals',
      method: 'GET',
      params,
    },
  },
}));

export const exportSignals = createAction(
  'patient/exportSignals',
  (params) => ({
    type: 'exportSignals',
    payload: {
      request: {
        url: '/signals',
        method: 'GET',
        params: {
          ...params,
          offset: 0,
          limit: 10000000,
        },
      },
    },
  })
);

export const getbreathalizerResults = createAction(
  'patient/getbreathalizerResults',
  (params) => ({
    type: 'getbreathalizerResults',
    payload: {
      request: {
        url: '/secure/breathalizerResults',
        method: 'GET',
        params,
      },
    },
  })
);

export const readAllSignal = createAction(
  'patient/readAllSignal',
  (params) => ({
    type: 'readAllSignal',
    payload: {
      request: {
        url: '/signalsAsRead',
        method: 'POST',
        params,
      },
    },
  })
);

export const getSchedule = createAction(
  'patient/getSchedule',
  (targetUser) => ({
    type: 'getSchedule',
    payload: {
      request: {
        url: '/schedule',
        method: 'GET',
        params: {
          targetUser,
        },
      },
    },
  })
);
