import { useActionModal } from '@components/shared';
import Tooltip from '@material-ui/core/Tooltip';
import Icon from '@mui/material/Icon';
import { PatientTranserForm } from './patient-transfer-form/patient-transfer-form';

export const PatientTransferButton = ({userId}) => {
  const { open, modal } = useActionModal(PatientTranserForm, {
    title: 'Flytta till annan admin',
    okText: 'Begär flytt'
  }, {userId});

  return (
    <>
      <Tooltip title="Flytta till annan admin">
        <Icon
          baseClassName="material-symbols-outlined"
          className="cursor-pointer font-light text-grey hover:text-blue"
          style={{ fontSize: '24px' }}
          onClick={() => open()}
        >
          outbound
        </Icon>
      </Tooltip>

      {modal}
    </>
  );
};
