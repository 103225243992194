import { useForm } from 'react-hook-form';
import { useDispatch } from 'react-redux';
import { updateTransferStatus } from '@redux/user-transfer';
import {useImperativeHandle} from 'react';

export const PatientTranserConfirm = ({ id, transfer, onSuccess }, ref) => {
  const dispatch = useDispatch();

  const { handleSubmit } = useForm();
  const onSubmit = async () => {
    await dispatch(updateTransferStatus({id: transfer.id, status: 'accept'}));
    alert('Klienten har nu flyttats till ditt konto.');
    onSuccess && onSuccess();
  };

  useImperativeHandle(ref, () => ({
    onCancel: async () => {
      await dispatch(updateTransferStatus({id: transfer.id, status: 'reject'}));
      alert(`Ett meddelande om att du nekat begäran har skickats till ${transfer.fromTherapistName}.`);
    },
  }));

  return (
    <form id={id} onSubmit={handleSubmit(onSubmit)} style={{ width: '485px' }}>
      <p>Administratören <strong>{transfer.fromTherapistName}</strong> har begärt att flytta klient <strong>{transfer.patientAlbusId}</strong> till ditt
        konto.
      </p>
    </form>
  );
};
