export const setExisting = (
  { mode, scheduleId, data, item, index, setData },
  dataObject
) => {
  const scheduleKey = `${scheduleId}`;
  let newData = { ...dataObject };
  // Add a new template to the schedule we work on
  // NOTE: make sure you update the loop further down
  // to detect the initial state.
  if (!newData.hasOwnProperty(scheduleKey)) {
    newData[scheduleKey] = {
      create: [],
      update: {},
      remove: {},
      start: null,
      stop: null,
    };
  }

  switch (mode) {
    case 'create':
      // Add a new item
      newData[scheduleKey].create.push(item);
      break;

    case 'undo':
      // this will trigger when the data of an item is unmodified
      // makes it easy for users to se that the scheudleitem is not modified
      if (
        item.scheduleItemId &&
        newData[scheduleKey].update[item.scheduleItemId]
      ) {
        delete newData[scheduleKey].update[item.scheduleItemId];
      }
      break;

    case 'update':
      // index of new item to work on
      if (index >= 0) {
        // won't update unless it already exists
        if (newData[scheduleKey].create[index]) {
          newData[scheduleKey].create[index] = item;
        } else {
        }
      } else {
        // data contains start/stop for schedule
        if (data) {
          newData[scheduleKey].start = data.start;
          newData[scheduleKey].stop = data.stop;
          newData[scheduleKey].sname = data.sname;

          // no data + item.scheduleItemId means we are updating an item
        } else if (item.scheduleItemId) {
          newData[scheduleKey].update[item.scheduleItemId] = item;
        }
      }
      break;

    case 'remove':
      // index of item to remove
      if (index >= 0) {
        if (newData[scheduleKey].create[index]) {
          newData[scheduleKey].create[index] = null;
        }
      } else {
        // true when an existing item is restored
        // to restore it we delete it from the data object
        if (newData[scheduleKey].remove[item.scheduleItemId]) {
          delete newData[scheduleKey].remove[item.scheduleItemId];

          // add item to data object marked for delete
        } else {
          newData[scheduleKey].remove[item.scheduleItemId] = item;
        }
      }
      break;

    default:
      break;
  }

  // this runs after every set and removes the schedule from the
  // data object when it equals the initial empty schedule data.
  for (const [key, value] of Object.entries(newData)) {
    const { create, update, remove, start, stop } = value;

    if (
      create.length === 0 &&
      Object.keys(update).length === 0 &&
      Object.keys(remove).length === 0 &&
      start === null &&
      stop === null
    ) {
      delete newData[key];
    }
  }
  setData(newData);
};

export const runJobs = ({
  data,
  updateSchedule,
  deleteScheduleItem,
  updateScheduleItem,
  newScheduleItem,
  dispatch,
  userId,
}) => {
  const jobs = [];


  for (const [scheduleId, scheduleData] of Object.entries(data)) {
    const { create, update, remove, start, stop, sname, deactive } = scheduleData;
    // Update start / stop of the schedule
    if (start || stop) {
      const data = {
        scheduleId,
        scheduleStart: start,
        scheduleEnd: stop,
        deactive,
      };
      if (sname) data.scheduleName = sname;
      data.userId = userId;
      jobs.push(dispatch(updateSchedule(data)));
    }

    // Run trough all items marked for removal
    // Deletes saved data for resorable items
    for (const [scheduleItemId] of Object.entries(remove)) {
      if (update.hasOwnProperty(scheduleItemId)) {
        delete update[scheduleItemId];
      }

      const data = { scheduleItemId, userId, scheduleId };
      jobs.push(dispatch(deleteScheduleItem(data)));
    }

    // Run trough all items marked for update
    // This should be run after the delete loop
    for (const [scheduleItemId, scheduleItem] of Object.entries(update)) {
      const data = {
        scheduleItemId,
        scheduleItemDayStart: scheduleItem.scheduleItemDayStart,
        scheduleItemDayEnd: scheduleItem.scheduleItemDayEnd,
        scheduleItemTimeStart: scheduleItem.scheduleItemTimeStart,
        scheduleItemTimeEnd: scheduleItem.scheduleItemTimeEnd,
        userId,
      };
      jobs.push(dispatch(updateScheduleItem(data)));
    }

    // Run trought all new items
    for (let i = 0; i < create.length; i++) {
      if (create[i] !== null) {
        const data = {
          scheduleId,
          scheduleItemDayStart: create[i].scheduleItemDayStart,
          scheduleItemDayEnd: create[i].scheduleItemDayEnd,
          scheduleItemTimeStart: create[i].scheduleItemTimeStart,
          scheduleItemTimeEnd: create[i].scheduleItemTimeEnd,
          userId,
        };
        jobs.push(dispatch(newScheduleItem(data)));
      }
    }
  }

  return Promise.all(jobs);
};
