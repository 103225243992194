import { createAction, createSelector, createSlice } from '@reduxjs/toolkit';

const initialState = {
  loggedIn: false,
  code: null,
  token: null,
  self: null,
  clients: [],
  clientsEnded: [],
  insight: false,
  pro: false,
};

const userSlice = createSlice({
  name: 'user',
  initialState,
  reducers: {
    logout: (state) => {
      console.log('LOGOUT');
      state.loggedIn = false;
      state.code = null;
      state.token = null;
      state.self = null;
      state.clients = null;
      state.clientsEnded = null;
      state.pro = false;
    },
    tokenRequest_SUCCESS: (state, { payload }) => {
      const {
        data: { message: code },
      } = payload;

      state.code = code;
    },
    requestToken_SUCCESS: (state, { payload }) => {
      const {
        data: { message: token },
      } = payload;

      state.token = token;
      state.loggedIn = true;
      state.code = null;
    },
    fetchSelf_SUCCESS: (state, { payload }) => {
      const {
        data: { user },
      } = payload;
      const { userFeatures } = user;

      console.log('fetchSelf', user);

      state.self = user;

      if (userFeatures && userFeatures.some((e) => e.featureName === 'pro')) {
        state.pro = true;
      }
    },
    fetchClients_SUCCESS: (state, { payload }) => {
      const {
        data: { users },
      } = payload;
      console.log('Clients_SUCCESS');
      state.clients = users;
    },
    fetchClientsEnded_SUCCESS: (state, { payload }) => {
      const {
        data: { users },
      } = payload;
      console.log('ClientsEnded_SUCCESS');
      state.clientsEnded = users;
    },
    updateClientRelation_SUCCESS: (state, { payload }) => {
      //const { data: { users } } = payload;
      console.log('updateClientRelation_SUCCESS');
      //state.clientsEnded = users;
    },
    newSchedule_SUCCESS: (state, { payload }) => {
      const {
        data: { userId },
      } = payload;
      console.log(userId);
      //state.ktm = userId;
    },
    accessChange: (state) => {
      state.pro = !state.pro;
    },
    deleteSchedule_SUCCESS: () => {
      console.log('delete schedule succes');
    },
  },
});

// Selector
const userSelector = (state) => state.user;

export const clientsSelector = createSelector(
  userSelector,
  ({ clients }) => clients
);

export const clientSelector = (clientID) =>
  createSelector(
    clientsSelector,
    (clients) => clients.filter(({ id }) => `${id}` === clientID)[0]
  );

export const tokenRequest = createAction(
  'user/tokenRequest',
  ({ username, password }) => ({
    type: 'tokenRequest',
    payload: {
      request: {
        url: '/tokenrequests',
        method: 'POST',
        auth: {
          username,
          password,
        },
      },
    },
  })
);

export const requestToken = createAction('user/requestToken', (code) => ({
  type: 'requestToken',
  payload: {
    request: {
      url: '/tokens',
      method: 'POST',
      headers: { Authorization: `OTP ${code}` },
    },
  },
}));

export const fetchSelf = createAction('user/fetchSelf', () => ({
  type: 'fetchSelf',
  payload: {
    request: {
      url: '/self',
      method: 'GET',
    },
  },
}));

export const fetchClients = createAction('user/fetchClients', () => ({
  type: 'fetchClients',
  payload: {
    request: {
      url: '/user?userid=0',
      method: 'GET',
    },
  },
}));

export const fetchClientsEnded = createAction('user/fetchClientsEnded', () => ({
  type: 'fetchClientsEnded',
  payload: {
    request: {
      url: '/user/clientsended',
      method: 'GET',
    },
  },
}));

export const updateClientRelation = createAction(
  'user/updateClientRelation',
  (data) => ({
    type: 'updateClientRelation',
    payload: {
      request: {
        url: '/user/updateclientrelation',
        method: 'PUT',
        data,
      },
    },
  })
);

export const updateProfile = createAction('user/updateProfile', (data) => ({
  type: 'updateProfile',
  payload: {
    request: {
      url: '/therapist/updatetherapist',
      method: 'POST',
      data,
    },
  },
}));

export const newUser = createAction('user/newUser', (data) => ({
  type: 'newUser',
  payload: {
    request: {
      url: '/user/newuser',
      method: 'POST',
      data,
    },
  },
}));

export const updateUser = createAction('user/updateUser', (data) => ({
  type: 'updateUser',
  payload: {
    request: {
      url: '/user/updateuser',
      method: 'POST',
      data,
    },
  },
}));

export const deleteUser = createAction('user/deleteUser', (data) => ({
  type: 'deleteUser',
  payload: {
    request: {
      url: '/user/deleteuser',
      method: 'PUT',
      data,
    },
  },
}));

export const forgotPassword = createAction('user/forgotPassword', (data) => ({
  type: 'forgotPassword',
  payload: {
    request: {
      url: '/user/forgot-password',
      method: 'POST',
      data,
    },
  },
}));

export const newSchedule = createAction('user/newSchedule', (data) => ({
  type: 'newSchedule',
  payload: {
    request: {
      url: '/schedule/newschedule',
      method: 'POST',
      data,
    },
  },
}));

export const newScheduleItem = createAction('user/newScheduleItem', (data) => ({
  type: 'newScheduleItem',
  payload: {
    request: {
      url: '/schedule/newscheduleItem',
      method: 'POST',
      data,
    },
  },
}));

export const deleteSchedule = createAction('schedule/', (data) => ({
  type: 'deleteSchedule',
  payload: {
    request: {
      url: '/schedule',
      method: 'DELETE',
      data,
    },
  },
}));

export const deleteScheduleItem = createAction('schedule/', (data) => ({
  type: 'deleteScheduleItem',
  payload: {
    request: {
      url: '/schedule/item',
      method: 'DELETE',
      data,
    },
  },
}));

export const updateSchedule = createAction(
  'schedule/updateSchedule',
  (data) => ({
    type: 'updateSchedule',
    payload: {
      request: {
        url: '/schedule/updateSchedule',
        method: 'PUT',
        data,
      },
    },
  })
);

export const updateScheduleItem = createAction(
  'schedule/updateScheduleItem',
  (data) => ({
    type: 'updateScheduleItem',
    payload: {
      request: {
        url: '/schedule/updateScheduleItem',
        method: 'PUT',
        data,
      },
    },
  })
);

export const terminate = createAction('schedule/terminate', (data) => ({
  type: 'terminate',
  payload: {
    request: {
      url: '/schedule/terminate',
      method: 'POST',
      data,
    },
  },
}));

export const sendRegSMS = createAction('sms/sendRegSMS', (data) => ({
  type: 'sendRegSMS',
  payload: {
    request: {
      url: '/sms/sendRegSMS',
      method: 'POST',
      data,
    },
  },
}));

//--------- SHOP --------
// TODO - move to own slice..

export const sendOrder = createAction('shop/sendOrder', (data) => ({
  type: 'sendOrder',
  payload: {
    request: {
      url: '/shop/sendorder',
      method: 'POST',
      data,
    },
  },
}));

const { reducer, actions } = userSlice;

export const { logout, accessChange } = actions;
export default reducer;
