import Avatar from '@material-ui/core/Avatar';
import { makeStyles } from '@material-ui/core/styles';
import Tooltip from '@material-ui/core/Tooltip';
import InfoOutlinedIcon from '@material-ui/icons/InfoOutlined';
import { Controller, useForm } from 'react-hook-form';
import { useDispatch } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { TagsInput } from 'react-tag-input-component';
import styles from './AddPatient.module.css';
import { isPhoneValid } from '@utils/validate';
import { newUser } from '../../redux/user';

const timeDiffOptions = [
  -13, -12.5, -12, -11.5, -11, -10.5, -10, -9.5, -9, -8.5, -8,
  -7.5, -7, -6.5, -6, -5.5, -5, -4.5, -4, -3.5, -3, -2.5, -2, -1.5, -1,
  -0.5, 0, 0.5, 1, 1.5, 2, 2.5, 3, 3.5, 4, 4.5, 5, 5.5, 6, 6.5, 7, 7.5,
  8, 8.5, 9, 9.5, 10, 10.5, 11, 11.5, 12, 12.5, 13
];

const useStyles = makeStyles((theme) => ({
  small: {
    width: theme.spacing(3),
    height: theme.spacing(3),
  },
  large: {
    width: theme.spacing(7),
    height: theme.spacing(7),
    backgroundColor: 'white',
    color: 'black',
    border: '1px solid ',
  },
}));

const StepOne = ({ setMsisdn, setNewUserId, Pro, url }) => {
  const {
    register,
    handleSubmit,
    control,
    setError,
    formState: { errors },
  } = useForm();
  const dispatch = useDispatch();
  const history = useHistory();
  const classes = useStyles();

  const onSubmit = (data) => {
    dispatch(newUser(data))
      .then((resp) => {
        setNewUserId(resp.payload.data.userId);
        console.log(resp);
      })
      .then(setMsisdn(data.msisdn))
      .then(() => {
        history.push(`${url}/steptwo`);
      })
      .catch((err) => {
        alert('Klientens telfonnummer finns redan...');
        console.log(err);
      });
  };

  return (
    <div>
      <div className='mt-5 mb-16'>
        <p>Steg 1 - Fyll klientens uppgifter och välj inställningar.</p>
      </div>
      <div className={styles.profile}>
        <form onSubmit={handleSubmit(onSubmit)}>
          <div className={styles.form}>
            <div className={styles.firstName}>
              <label>FÖRNAMN *</label>
              <input
                type="text"
                {...register('firstname', {
                  required: true,
                  maxLength: 20,
                  pattern: /^[A-Za-zäöåÄÖÅ]+$/i,
                })}
                placeholder="Förnamn"
              />
              {errors.firstname && errors.firstname.type === 'required' && (
                <span className="error-helper">Du måste skriva in ett förnamn</span>
              )}
              {errors.firstname && errors.firstname.type === 'maxLength' && (
                <span className="error-helper">Förnamnet är för långt!</span>
              )}
              {errors.firstname && errors.firstname.type === 'pattern' && (
                <span className="error-helper">Får bara innehålla bokstäver</span>
              )}
            </div>
            <div className={styles.lastName}>
              <label>EFTERNAMN *</label>
              <input
                type="text"
                {...register('lastname', {
                  required: true,
                  maxLength: 20,
                  pattern: /^[A-Za-zäöåÄÖÅ]+$/i,
                })}
                placeholder="Efternamn"
              />
              {errors.lastname && errors.lastname.type === 'required' && (
                <span className="error-helper">Du måste skriva in ett efternamn</span>
              )}
              {errors.lastname && errors.lastname.type === 'maxLength' && (
                <span className="error-helper">Efternamnet är för långt!</span>
              )}
              {errors.lastname && errors.lastname.type === 'pattern' && (
                <span className="error-helper">Får bara innehålla bokstäver</span>
              )}
            </div>
            <div className={styles.phonenumber}>
              <label>
                KLIENTENS TELEFONNUMMER *
              </label>
              <input
                type="text"
                {...register('msisdn', {
                  required: true,
                  maxLength: 10,
                  minLength: 8,
                })}
                placeholder="Telefonnummer"
              ></input>
              {errors.msisdn && errors.msisdn.type === 'required' && (
                <span className="error-helper">Du måste fylla i ett telefonnummer</span>
              )}
              {errors.msisdn && errors.msisdn.type === 'maxLength' && (
                <span className="error-helper">Telefonnummret har för många sifror. max 10</span>
              )}
              {errors.msisdn && errors.msisdn.type === 'minLength' && (
                <span className="error-helper">Telfonnummret innehåller för få siffror, min 8</span>
              )}
            </div>

            <div className={styles.altpatientId} style={{gridColumn: "3/4"}}>
              <div className={styles.flex}>
                <label>EGET ID</label>
              </div>
              <input
                type="text"
                {...register('clientid', { maxLength: 12 })}
                placeholder="Eget ID"
              ></input>
              {errors.clientid && errors.clientid.type === 'maxLength' && (
                <span className="error-helper">ID:et är för långt. max 12</span>
              )}
            </div>
            <div className={styles.contactPhonenumber}>
              <div className={styles.flex}>
                <label>TELEFONNUMMER FÖR RESULTATDELNING</label>
                <Tooltip title="Du kan välja att automatiskt dela resultat via SMS till exempelvis dig själv, en
                              chef eller anhörig. Skriv in mottagarens mobilnummer i formatet 0701234567
                              och tryck på enter.">
                  <InfoOutlinedIcon style={{ color: 'blue' }} />
                </Tooltip>
              </div>
              <Controller
                name="smsrecipients"
                control={control}
                render={({ field: { ref, ...field } }) => (
                  <TagsInput
                    placeHolder="Tryck på enter för att lägga till ett nytt telefonnummer"
                    beforeAddValidate={(phone) => {
                      const isValid = isPhoneValid(phone);
                      if (!isValid) {
                        console.log('set error');
                        setError('smsrecipients', { type: 'isPhoneNumber' });
                      }
                      return isValid;
                    }}
                    {...field}
                  />
                )}
              />
              {errors?.smsrecipients?.type === 'isPhoneNumber' && (
                <span className="error-helper">Telefonnumret är inte i rätt format</span>
              )}
            </div>

            <div className={styles.signal}>
              <div className={styles.flex}>
                <label>DELA RESULTAT VIA SMS VID:</label>
                <Tooltip title="Här väljer du vilka resultat som ska skickas till telefonnumret för resultatdelning.">
                  <InfoOutlinedIcon style={{ color: 'blue' }} />
                </Tooltip>
              </div>
              <select type="text" {...register('smslevel')}>
                <option value="">Missade och över promillegräns</option>
                <option value="all">Alla resultat</option>
              </select>
            </div>

            <p className={styles.threshold}>
              <label style={{ marginRight: "10px" }}>PROMILLEGRÄNS</label>
              <Tooltip title="Välj var gränsen går mellan ett godkänt och icke godkänt resultat.">
                <InfoOutlinedIcon style={{ color: 'blue' }} />
              </Tooltip>
              <select
                type="number"
                defaultValue={'0.00'}
                {...register('threshold')}
              >
                <option value="0.00">0.00</option>
                <option value="0.10">0.10</option>
                <option value="0.20">0.20</option>
              </select>
            </p>
            <div style={{gridRow: "4/5"}}>
              <label style={{ marginRight: "10px" }}>TIDSSKILLNAD</label>
              <Tooltip title="Befinner sig klienten i en annan tidszon? Ange då här hur många timmars tidsskillnad det är mot svensk tid (CET).">
                <InfoOutlinedIcon style={{ color: 'blue' }} />
              </Tooltip>
              <select type="number" {...register('timeDiff')} defaultValue={0}>
                {timeDiffOptions.map((time, index) => (
                  <option  key={index} value={time}>{`${time < 0 ? time : `+${time}`} h`}</option>
                ))}
              </select>
            </div>
            <div className={styles.ppmVisible}>
              <div className={styles.flex}>
                <label>VISA MÄTRESULTAT FÖR KLIENTEN?</label>
              </div>
              <select {...register('ppmVisible')}>
                <option value={false} >Nej, visa inget promille efter blås</option>
                <option value={true} >Ja, visa promille efter blås</option>
              </select>
            </div>

          </div>
          <button
            className={styles.btn}
            type="button"
            onClick={() => {
              handleSubmit(onSubmit)();
            }}
          >
            Aktivera klient
          </button>
        </form>
      </div>
    </div>
  );
};

export default StepOne;
