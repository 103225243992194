import Trash from '@mui/icons-material/DeleteOutlined';
import Restore from '@mui/icons-material/Undo';
import moment from 'moment';
import { useEffect, useState } from 'react';

import styles from './Schedule.module.css';

import DayPicker from './DayPicker';
import TimePicker from './TimePicker';

const FakeDate = (value) => `2020-01-01 ${value}`;

const ScheduleItem = ({
  item,
  set,
  index,
  deleteItem = false,
  newIndex,
  labelNr = null,
  disableInput,
  setErr,
  err,
}) => {
  const {
    scheduleItemTimeStart,
    scheduleItemTimeEnd,
    scheduleItemDayStart,
    scheduleItemDayEnd,
    scheduleId,
  } = item;

  const [timeError, setTimeError] = useState('');
  const [dayError, setDayError] = useState('');

  const [timeStart, setTimeStart] = useState(scheduleItemTimeStart);
  const [timeStop, setTimeStop] = useState(scheduleItemTimeEnd);
  const [dayStart, setDayStart] = useState(scheduleItemDayStart);
  const [dayStop, setDayStop] = useState(scheduleItemDayEnd);
  const [init, setInit] = useState(true);

  const isNew = !item.hasOwnProperty('scheduleItemId');

  const handleInput = ({ target }) => {
    let { name, value } = target;

    if (name === 'timeStart') {
      setTimeStart(value);
    }
    if (name === 'timeStop') {
      setTimeStop(value);
    }

    if (name === 'dayStart' || name === 'dayStop') {
      value = parseInt(value, 10);

      if (name === 'dayStart') {
        setDayStart(value);
      }
      if (name === 'dayStop') {
        setDayStop(value);
      }
    }
  };

  const onDelete = () => {
    // eslint-disable-next-line
    if (!isNew) {
      set({
        mode: 'remove',
        scheduleId,
        item: {
          ...item,
          scheduleItemTimeStart: timeStart,
          scheduleItemTimeEnd: timeStop,
          scheduleItemDayStart: dayStart,
          scheduleItemDayEnd: dayStop,
        },
      });
    } else {
      set({
        mode: 'remove',
        scheduleId: scheduleId || 0, // Default to 0 if we are creating a new schedule
        index: newIndex,
      });
    }
  };

  useEffect(() => {
    // Weird init behavior. Breaks save after adding new items
    // Run set after first run is ignored..
    if (init) {
      setInit(false);
    } else {
      const unmodified =
        timeStart === scheduleItemTimeStart &&
        timeStop === scheduleItemTimeEnd &&
        dayStart === scheduleItemDayStart &&
        dayStop === scheduleItemDayEnd;

      if (newIndex >= 0) {
        set({
          mode: 'update',
          scheduleId: scheduleId || 0, // Default to 0 if we are creating a new schedule
          index: newIndex,
          item: {
            ...item,
            scheduleItemTimeStart: timeStart,
            scheduleItemTimeEnd: timeStop,
            scheduleItemDayStart: dayStart,
            scheduleItemDayEnd: dayStop,
          },
        });
      } else {
        set({
          mode: unmodified ? 'undo' : 'update',
          scheduleId,
          item: {
            ...item,
            scheduleItemTimeStart: timeStart,
            scheduleItemTimeEnd: timeStop,
            scheduleItemDayStart: dayStart,
            scheduleItemDayEnd: dayStop,
          },
        });
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    timeStart,
    timeStop,
    dayStart,
    dayStop,
    scheduleItemTimeStart,
    scheduleItemTimeEnd,
    scheduleItemDayStart,
    scheduleItemDayEnd,
  ]);

  useEffect(() => {
    setTimeError('');
    setDayError('');

    const validateTime = moment(FakeDate(timeStart)).isBefore(
      FakeDate(timeStop)
    );

    if (!validateTime) {
      setTimeError('Starttid måste vara före stopptid');
    }

    const start = parseInt(dayStart, 10);
    const stop = parseInt(dayStop, 10);

    if (start > stop) {
      setDayError('Startdag måste vara före stopdag');
    }
  }, [timeStart, timeStop, dayStart, dayStop]);

  useEffect(() => {
    if (setErr) {
      const key = `err-${index}`;

      if (dayError !== '' || (timeError !== '' && !err.hasOwnProperty(key))) {
        let temp = err;
        temp[key] = 1;
        setErr(temp);
      } else if (
        dayError === '' &&
        timeError === '' &&
        err.hasOwnProperty(key)
      ) {
        let temp = err;
        delete temp[key];
        setErr(temp);
      }
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dayError, timeError, err]);

  const label = `Tillfälle ${(labelNr !== null ? labelNr : index) + 1}`;
  let className = styles.itemLi;

  return (
    <li key={item.scheduleItemId} className={className}>
      <div className={styles.flexItem}>
        <p className={styles.scheduleItemLabel}>{label}</p>
        <div className={styles.flexTime}>
          <label>Tid: </label>
          <TimePicker
            name="timeStart"
            type="time"
            value={timeStart.substr(0, 5)}
            onChange={handleInput}
            disabled={deleteItem || disableInput}
            error={timeError}
          />
          <p className={styles.diva}>-</p>
          <TimePicker
            name="timeStop"
            type="time"
            value={timeStop.substr(0, 5)}
            onChange={handleInput}
            disabled={deleteItem || disableInput}
            error={timeError}
          />
        </div>
        <div className={styles.flexDays}>
          <label>Dagar: </label>
          <DayPicker
            name="dayStart"
            onChange={handleInput}
            value={dayStart}
            className={styles.dayStart}
            disabled={deleteItem || disableInput}
            error={dayError}
          />
          <p className={styles.diva}>-</p>
          <DayPicker
            name="dayStop"
            onChange={handleInput}
            value={dayStop}
            className={styles.dayStart}
            disabled={deleteItem || disableInput}
            error={dayError}
          />
        </div>
        {!disableInput &&
          (deleteItem ? (
            <Restore onClick={onDelete} />
          ) : (
            <Trash onClick={onDelete} />
          ))}
      </div>
      {(timeError !== '' || dayError !== '') && (
        <div className="errors hasError">
          {[timeError, dayError].filter((err) => err !== '').join(', ')}
        </div>
      )}
    </li>
  );
};

export default ScheduleItem;
