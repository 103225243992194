import iconAlbus from '@images/icon_albus.png';
import styles from './home.module.css';

export default function Home() {
  return (
    <div className={styles.container}>
      <img className={styles.imgMob} src={iconAlbus} alt="albus" />
      <div className={styles.itemB}>
        <p className={styles.hInsight}>Välkommen till Albus</p>
        <p className={styles.content}>
          Kom enkelt igång genom att aktivera nya klienter och beställa
          alkomätare via menyn till vänster.
        </p>
        <p className={styles.content} style={{ marginTop: '24px' }}>
          Om du skulle behöva hjälp är du alltid välkommen att kontakta vår
          support.
        </p>
      </div>
    </div>
  );
}
