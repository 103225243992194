import Button from '@mui/material/Button';
import Stack from '@mui/material/Stack';
import { useEffect, useState } from 'react';
import Modal from 'react-modal';
import { useDispatch, useSelector } from 'react-redux';
import styles from './PatientSignals.module.css';
import SignalsFilter from './SignalsFilter/SignalsFilter';
import SignalsTable from './SignalsTable/SignalsTable';
import SignalsTools from './SignalsTool/SignalsTool';

import {
  getbreathalizerResults,
  getSignals,
  loadingSelector,
  signalsSelector
} from '@redux/patient';

Modal.setAppElement('#root');

const PatientSignals = ({ patient }) => {
  const dispatch = useDispatch();
  const signals = useSelector(signalsSelector);
  const isLoading = useSelector(loadingSelector);
  const [filter, setFilter] = useState({
    offset: 0,
    limit: 50,
    startTime: null,
    endTime: null,
  });

  const fetchSignals = () => {
    dispatch(
      getSignals({
        targetUser: patient.id,
        ...filter,
        startTime: filter.startTime?.startOf('day').toDate(),
        endTime: filter.endTime?.endOf('day').toDate(),
      })
    );

    dispatch(
      getbreathalizerResults({
        targetUser: patient.id,
        ...filter,
        images: false,
      })
    );
  };

  useEffect(() => {
    fetchSignals();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [patient.id, filter]);

  const updateFilter = (newFilter) => {
    setFilter({
      ...filter,
      ...newFilter,
    });
  };

  return (
    <>
      <div className={styles.head}>
        <Stack direction="row" spacing={2} justifyContent="space-between">
          <SignalsFilter
            onChange={(newFilter) => {
              updateFilter({ ...newFilter, offset: 0 });
            }}
          ></SignalsFilter>
          <SignalsTools filter={filter} refetch={fetchSignals}></SignalsTools>
        </Stack>
      </div>

      {isLoading ? (
        <p>loading...</p>
      ) : (
        <>
          <div className={styles.headBtn}>
            <p>Antal resultat: {signals.nr} </p>
            <Stack direction="row" spacing={1}>
              <Button
                variant="contained"
                style={{ borderRadius: 100 }}
                disabled={filter.offset === 0}
                onClick={() =>
                  updateFilter({
                    offset: filter.offset - filter.limit,
                  })
                }
              >
                Framåt
              </Button>
              <Button
                variant="contained"
                style={{ borderRadius: 100 }}
                disabled={filter.offset + filter.limit >= signals.nr}
                onClick={() =>
                  updateFilter({
                    offset: filter.offset + filter.limit,
                  })
                }
              >
                Bakåt
              </Button>
            </Stack>
          </div>

          <SignalsTable></SignalsTable>
        </>
      )}
    </>
  );
};

export default PatientSignals;
