import React from 'react';

const TimePicker = ({ name, value, onChange, disabled, error }) => (
  <input
    name={name}
    type="time"
    value={value}
    onChange={onChange}
    disabled={disabled}
    className={error === '' ? '' : 'hasError'}
    title={error}
  />
);

export default TimePicker;
