import { createTheme, ThemeProvider } from '@mui/material/styles';
import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { BrowserRouter, Route, Switch } from 'react-router-dom';

import './App.css';
import HeadCras from './components/header';
import LoginPage from './pages/LoginPage';
import ForgotPassword from './pages/ForgotPassword';
import {
  AddPatientPage,
  CalendarPage,
  ControlPanelPage,
  DocumentsPage,
  HomePage,
  MeasurementsPage,
  PatientinformationPage,
  PatientOverviewPage,
  SchedulePage,
  ShopPage,
  SupportPage,
  TherapistProfilePage,
  UserProfilePage
} from './pages/pages';

import {PatientTransferInfo} from '@components/patient/patient-transfer/patient-transfer-info/patient-transfer-info';

import { fetchClients, fetchSelf } from './redux/user';

const theme = createTheme({
  palette: {
    primary: {
      main: '#083EA7',
    },
  },
  typography: {
    fontFamily: `-apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif`,
    button: {
      textTransform: 'none',
      fontWeight: 400,
      whiteSpace: 'nowrap',
      minWidth: 'auto',
    },
  },
  // shape: {
  //   borderRadius: '100px'
  // }
});

export default function App() {
  const dispatch = useDispatch();
  const loggedIn = useSelector(({ user: { loggedIn } }) => loggedIn);

  useEffect(() => {
    if (!loggedIn) return;

    dispatch(fetchSelf())
      .then(() => dispatch(fetchClients()))
      .then(console.log)
      .catch(console.log);
  }, [loggedIn, dispatch]);

  const renderAuth = () => {
    return (
      <Switch>
          {/* <Route exact path="/forgot-password">
            <ForgotPassword />
          </Route> */}
          <Route path="/">
            <LoginPage />;
          </Route>
      </Switch>

    )
  };

  const renderApp = () => {
    return (
      <>
        <HeadCras />
        <PatientTransferInfo/>
        <Switch>
          <Route exact path="/">
            <HomePage />
          </Route>
          <Route path="/controlpanel">
            <ControlPanelPage />
          </Route>
          <Route path="/patientoversikt/:clientID">
            <PatientOverviewPage />
          </Route>
          <Route exact path="/matningar/:clientID">
            <MeasurementsPage />
          </Route>
          <Route exact path="/patientinformation/:clientID">
            <PatientinformationPage />
          </Route>
          <Route exact path="/userprofile">
            <UserProfilePage />
          </Route>
          <Route exact path="/therapistprofile">
            <TherapistProfilePage />
          </Route>
          <Route exact path="/support">
            <SupportPage />
          </Route>
          <Route path="/schedule/:clientID">
            <SchedulePage />
          </Route>
          <Route exact path="/documents">
            <DocumentsPage />
          </Route>
          <Route path="/addpatient">
            <AddPatientPage />
          </Route>
          <Route exact path="/shop">
            <ShopPage />
          </Route>

          <Route exact path="/calendar/:clientID">
            <CalendarPage />
          </Route>
        </Switch>
      </>
    );
  };

  return (
    <ThemeProvider theme={theme}>
      <div className="App">
        <BrowserRouter basename="/">
          {loggedIn ? renderApp() : renderAuth()}
        </BrowserRouter>
      </div>
    </ThemeProvider>
  );
}
