import React, { useEffect } from 'react';
import { NavLink } from "react-router-dom";
import {
  Switch,
  Route,
  useRouteMatch,
} from "react-router-dom";
import styles from './ControlPanel.module.css'
import ActiveTable from './ActiveTable'
import EndedTable from './EndedTable'
import { useDispatch } from 'react-redux';
import { fetchClients, fetchClientsEnded } from '../../redux/user';
import { useWindowHeight } from '@react-hook/window-size';

export default function ControlPanel() {
  const dispatch = useDispatch();

  useEffect(() => {
    const fetchPatients = async () => {
      try {
        await Promise.all([
          dispatch(fetchClients()),
          dispatch(fetchClientsEnded())
        ])
      } catch (err) {
        console.log('err: ', err);
      }
    }

    fetchPatients();

  }, [dispatch]);

  let { path, url } = useRouteMatch();

  return (
    <div className={styles.nav}>
      <ul>
        <li>
          <NavLink to={`${url}/activepatient`} activeClassName={styles.act}>Aktiva klienter</NavLink>
        </li>
        <li>
          <NavLink to={`${url}/endedpatient`} activeClassName={styles.act}>Avslutade klienter</NavLink>
        </li>
      </ul>
      <Switch>
        <Route exact path={path}>
          <h3>Please select a topic.</h3>
        </Route>
        <Route path={`${path}/activepatient`}>
          <PatientTable type={'active'} />
        </Route>
        <Route path={`${path}/endedpatient`}>
          <PatientTable type={'ended'} />
        </Route>
      </Switch>
    </div>
  );
}

export const PatientTable = ({ type }) => {
  const tabName = type === "active" ? "Aktiva klienter" : "Avslutade klienter"
  const height = useWindowHeight() - 262;

  return (
    <div className={styles.container}>
      <div className={styles.top}>
        <h2>{tabName}</h2>
      </div>
      <div className={styles.controlBox}>
        <div className={styles.item1} style={{ height: `${height}px` }}>
          {
            type === "active"
              ? <ActiveTable />
              : <EndedTable />
          }
        </div>
      </div>
    </div>
  );
}
