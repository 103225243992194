import React from 'react';

const DayPicker = ({ name, onChange, value, className, disabled, error }) => (
  <select
    name={name}
    onChange={onChange}
    value={value}
    className={error === '' ? className : `${className} hasError`}
    disabled={disabled}
    title={error}
  >
      <option value=""></option>
      <option value="1">Mån</option>
      <option value="2">Tis</option>
      <option value="3">Ons</option>
      <option value="4">Tor</option>
      <option value="5">Fre</option>
      <option value="6">Lör</option>
      <option value="7">Sön</option>
  </select>
);

export default DayPicker;
