import React from 'react'
import styles from './MeasureItem.module.css'
import yes from '../../images/yes.png'
import nope from '../../images/nope.png'


const VAS = ({ vas }) => {
  let styl;
      if (vas > 6) {
        styl = styles.green;
      } else if (vas < 4) {
        styl = styles.red;
      } else {
        styl = styles.yellow;
      }
    return (
        <div>
            <p>Mående</p>
            { Math.sign(vas) !== -1 ?
              <p className={styl}>{vas} av 10</p> :
              <p>.</p>
            }
        </div>
    )
}


const MeasureItem = ({ vas, createdAt, result, image, ppmLimit  }) => (
    <div className={styles.card}>
      <div className={styles.card_img}>
        {
            image
            && image.length > 0
            && (
              <img src={`data:image/png;base64,${image}`} alt='img' />
            )
          }
      </div>
      <div className={styles.card_results}>
                <p>Datum</p>
                <div className={styles.dateRes}>
                  <p className={styles.date}>{createdAt.slice(0, 10)}</p>
                  <p className={styles.time}>{createdAt.slice(11)}</p>
                </div>
                
                <p>Resultat</p>
                <div className={styles.result}>
                  <img src={ ppmLimit >= result ? yes : nope} width="20px" height="20px" alt="" />
                  <p>{result} ‰ </p>
                </div>
               { <VAS vas={vas} /> }
      </div> 
    </div>
)

export default MeasureItem
