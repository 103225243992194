import React from 'react'
import { Page, Text, View, Document, Image, StyleSheet, Font, Link } from '@react-pdf/renderer';
import signalUnderPpmLimit from '@images/underPpmLimit.png'
import signalMissedScheduleItem from '@images/missedScheduleItem.png'
import signalAbovePpmLimit from '@images/srinkAbovePpm.png';
import offlineIcon from '@images/offline.png';
import albusDarkBlue from "@images/albus_drak_blue.png";
import Roboto from "../../../../../assets/fonts/Roboto-Thin.ttf";
import RobotoBold from "../../../../../assets/fonts/Roboto-Bold.ttf";
import RobotoMedium from "../../../../../assets/fonts/Roboto-Medium.ttf";

const Signals = ({ sig }) => {
  const { signalType: sigType } = sig;
  let symbol = signalAbovePpmLimit, sigTypeText;

  switch (sigType) {
    case "underPpmLimit":
      symbol = signalUnderPpmLimit;
      sigTypeText = 'Utandningstest inom gränsvärdet';
      break;
    case "abovePpmLimit":
      symbol = signalAbovePpmLimit;
      sigTypeText = 'Överskridet gränsvärde';
      break;
    case "missedScheduleItem":
      symbol = signalMissedScheduleItem;
      sigTypeText = 'Missat utandningstest';
      break;
    default:
      symbol = signalMissedScheduleItem;
  };

  return (
    <>
      <Image
        style={styles.symImg}
        src={symbol}
      />
      {sig.offline ? (
        <Image
          style={styles.symOffline}
          src={offlineIcon}
        />
      ) : null}
      <Text style={[styles.textRes, { width: 70 }]}>{sig.breathRes && sig.breathRes.result}</Text>
      <Text style={[styles.textRes, { width: 150 }]}>{sigTypeText}</Text>
    </>
  );
};

const SignalsSum = ({ sig }) => {
  let symbol;

  switch (sig) {
    case "underPpmLimit":
      symbol = signalUnderPpmLimit;
      break;
    case "abovePpmLimit":
      symbol = signalAbovePpmLimit;
      break;
    case "missedScheduleItem":
      symbol = signalMissedScheduleItem;
      break;
    default:
      symbol = signalMissedScheduleItem;
  };

  return (
    <>
      <Image
        style={{ width: 15, height: 15, marginRight: 4, marginLeft: 4 }}
        src={symbol}
      />
    </>
  );
};

const Rows = ({ signals }) => {
  return (
    <>
      {
        signals
        && signals.length > 0
        && signals.map((item, index) => {
          const time = item.offline || item.createdAt;
          return (
            <View key={item.signalId} style={[styles.tableRow, index % 2 === 0 && styles.tableRowNotBackground]}>
              <Signals sig={item} />
              <Text style={[styles.textRes, { width: 80 }]}>{time.slice(0, 10)}</Text>
              <Text style={[styles.textRes, { width: 80 }]}>{time.slice(11)}</Text>
              <Text style={[styles.textRes, { width: 200 }]}>{(parseFloat(item.ppmLimit)).toFixed(1)}</Text>
            </View>
          )
        })
      }
    </>
  )
}

const SignalSummary = ({ signalSummary }) => {
  if (!signalSummary || signalSummary.length === 0) return null;

  return (
    <>
      <View style={styles.signalSum}>
        <Text style={styles.signalSumTxt}>Summering: </Text>
        {
          signalSummary
          && signalSummary.length > 0
          && signalSummary.map(({ count, signalType }, index) => (
            <View key={index} style={styles.symbolFlex}>
              <Text style={[styles.signalSumTxt, styles.count]}>{count}</Text>
              <SignalsSum sig={signalType} />
            </View>
          ))
        }
      </View>
    </>
  )
}

const Quixote = ({ nr, signals, signalSummary, patient }) => (
  <Document>
    <Page style={styles.body}>
      <View style={{ flexDirection: "column", alignItems: "center" }}>
        <Image
          cache={false}
          style={styles.imageBac}
          src={albusDarkBlue}
        />
        <Text style={styles.title}>ALBUS RESULTATRAPPORT</Text>
      </View>

      <View style={styles.info}>
        <Text style={styles.userText}>
          Albus-ID:{' '}
        </Text>
        <Text style={styles.userTextValue}>
          {patient.usercode.toUpperCase()}
        </Text>
      </View>

      <View style={styles.signalSumnr}>
        <Text style={styles.totSignals}>
          Antal resultat: <Text style={styles.bold}>{nr}</Text>
        </Text>
        <SignalSummary signalSummary={signalSummary} />
      </View>

      <View style={styles.tableHead}>
        <Text style={[styles.textHead, { width: 50 }]}>Signal</Text>
        <Text style={[styles.textHead, { width: 80 }]}>Resultat(‰)</Text>
        <Text style={[styles.textHead, { width: 150 }]}>Beskrivning</Text>
        <Text style={[styles.textHead, { width: 80 }]}>Datum</Text>
        <Text style={[styles.textHead, { width: 80 }]}>Tidpunkt</Text>
        <Text style={[styles.textHead, { width: 200 }]}>Promillegräns(‰)</Text>
      </View>
      <Rows signals={signals} />

      <Text style={styles.pageNumber} render={({ pageNumber, totalPages }) => (
        `Sida ${pageNumber} (${totalPages})`
      )} fixed >
      </Text>
      <Text style={styles.email} fixed>
        ALBUS CARE AB | info@albus.care
      </Text>
      <Text style={styles.www} fixed>
        <Link src="www.albuscare.com">
          albuscare.com
        </Link>
      </Text>
    </Page>
  </Document>
);

Font.register({
  family: 'Oswald',
  src: 'https://fonts.gstatic.com/s/oswald/v13/Y_TKV6o8WovbUd3m_X9aAA.ttf',
  fontWeight: 'bold'
}
);
Font.register({
  family: 'Roboto',
  fonts: [
    { src: Roboto },
    { src: RobotoBold, fontWeight: 'bold' },
  ]
});
Font.register({
  family: 'Roboto-Medium',
  fonts: [
    { src: RobotoMedium },
  ]
});

const styles = StyleSheet.create({
  body: {
    paddingTop: 25,
    paddingBottom: 65,
    paddingHorizontal: 20,
    color: '#1E1919',
  },
  title: {
    fontSize: 14,
    color: '#1E1919',
  },
  subtitle: {
    fontSize: 16,
    margin: 12,
    fontFamily: 'Oswald'
  },
  text: {
    margin: 8,
    fontSize: 14,
    textAlign: 'justify',
    fontFamily: 'Times-Roman'
  },
  image: {
    marginVertical: 15,
    marginHorizontal: 100,
    width: 22,
    height: 22
  },
  imageSymbol: {
    marginVertical: 2,
    width: 22,
    height: 22
  },
  imageBac: {
    width: 60,
  },
  imageLogo: {
    marginVertical: 15,
    width: 210,
    height: 100
  },
  textRes: {
    marginLeft: 2,
    padding: 3,
    fontSize: 8,
  },
  header: {
    fontSize: 12,
    marginBottom: 20,
    textAlign: 'center',
  },
  pageNumber: {
    position: 'absolute',
    fontSize: 8,
    bottom: 20,
    left: 0,
    right: 20,
    textAlign: 'right',
    fontFamily: 'Roboto',
    fontWeight: 'bold'
  },
  email: {
    position: 'absolute',
    fontSize: 8,
    bottom: 20,
    left: 10,
    right: 0,
    textAlign: 'left',
    fontFamily: 'Roboto',
    fontWeight: 'bold'
  },
  www: {
    position: 'absolute',
    fontSize: 8,
    bottom: 20,
    left: 10,
    right: 0,
    textAlign: 'center',
    fontFamily: 'Roboto',
    fontWeight: 'bold'
  },
  section: {
    display: "flex",
    flexDirection: "row",
    flexWrap: "nowrap",
    alignItems: "center",
    margin: 5,
    fontSize: 12,
  },
  tableHead: {
    display: "flex",
    flexDirection: "row",
    backgroundColor: '#EBEBEB',
    marginBottom: 4,
  },
  userInfo: {
    display: "flex",
    flexDirection: "row",
    justifyContent: 'space-between',
    fontSize: 7,
  },
  info: {
    flexDirection: "row",
    alignItems: "center",
    marginTop: 13,
    marginBottom: 15,
    fontSize: 10,
  },
  userTextValue: {
    fontFamily: 'Roboto',
    fontWeight: 'bold'
  },
  tableRow: {
    display: "flex",
    flexDirection: "row",
    backgroundColor: '#F6F6F8',
    alignItems: 'center',
    borderBottom: 1,
    borderBottomColor: 'white',
  },
  tableRowNotBackground: {
    backgroundColor: '#fff',
  },
  textHead: {
    marginLeft: 2,
    padding: 8,
    fontSize: 11,
    fontFamily: 'Roboto-Medium'
  },
  textCE: {
    padding: 8,
    fontSize: 11,
    textAlign: 'justify',
    fontFamily: 'Oswald'
  },
  totSignals: {
    fontSize: 10,
  },
  signalSum: {
    display: "flex",
    flexDirection: "row",
    justifyContent: 'flex-end',
    alignItems: 'center',
    marginBottom: 8,
  },
  signalSumTxt: {
    fontSize: 10,
  },
  symbolFlex: {
    display: "flex",
    flexDirection: "row",
    alignItems: 'center',
  },
  signalSumnr: {
    display: "flex",
    flexDirection: "row",
    justifyContent: 'space-between',
  },
  bold: {
    fontFamily: 'Roboto',
    fontWeight: "bold",
    fontSize: 10,
    marginLeft: 15,
  },
  count: {
    fontFamily: 'Roboto',
    fontWeight: "bold",
  },
  symImg: {
    width: 20,
    height: 20,
    marginRight: 33,
    marginLeft: 13,
    padding: 3,
  },
  symOffline: {
    position: 'absolute',
    width: 11,
    height: 11,
    top: 8,
    left: 25,
  },

});


export default Quixote
