import React from 'react';
import styles from './SideNav.module.css';
import logoutSvg from '../../svg/logout.svg';
import support from '../../svg/headphones-customer-support.svg'
import ImportContactsTwoToneIcon from '@material-ui/icons/ImportContactsTwoTone';
import AssignmentIndOutlinedIcon from '@material-ui/icons/AssignmentIndOutlined';
import AddCircleOutlineIcon from '@material-ui/icons/AddCircleOutline';
import ShoppingCartOutlinedIcon from '@material-ui/icons/ShoppingCartOutlined';
import { useHistory, NavLink } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { logout } from '../../redux/user';

function SideNav() {
  const history = useHistory();
  const dispatch = useDispatch();
  const Pro = useSelector(({ user: { pro } }) => pro);

  const handleLogout = () => {
    dispatch(logout());
    history.push('/');
  }

  const controlHouse = () => {
    history.push('/controlpanel/activepatient')
  }

  return (
    <>
      <div className={styles.sidenav}>
        <div className={styles.top} onClick={controlHouse} >
          <div className={styles.house}>
            <span className="material-symbols-rounded" style={{ color: "grey" }}>
              groups
            </span>
          </div>
          <p>Klienter</p>
        </div>
        <div>
          <NavLink to="/addpatient/stepone" className={styles.bottomManDoc} activeClassName={styles.act}>
            <div className={styles.support}>
              <AddCircleOutlineIcon style={{ color: "grey" }} />
            </div>
            <p>Aktivera ny klient</p>
          </NavLink>
          <NavLink to="/shop" className={styles.bottomManDoc} activeClassName={styles.act}>
            <div className={styles.support}>
              <ShoppingCartOutlinedIcon style={{ color: "grey" }} />
            </div>
            <p>Beställ alkomätare</p>
          </NavLink>
          {
            !Pro
            && (
              <NavLink to="/documents" className={styles.bottomManDoc} activeClassName={styles.act}>
                <div className={styles.support}>
                  <ImportContactsTwoToneIcon style={{ color: "grey" }} />
                </div>
                <p>
                  Kunskapsbank
                </p>
              </NavLink>
            )
          }
          <NavLink to="/userprofile" className={styles.bottomManDoc} activeClassName={styles.act}>
            <div className={styles.support}>
              <AssignmentIndOutlinedIcon style={{ color: "grey" }} />
            </div>
            <p>Kundprofil</p>
          </NavLink>
        </div>
        <div className={styles.flex1}></div>
        <div className={styles.bottom}>
          <NavLink to="/Support" className={styles.bottomsupport} activeClassName={styles.act}>
            <div className={styles.support}>
              <img src={support} alt="support" />
            </div>
            <p>Support</p>
          </NavLink>
          <div className={styles.bottomlogout} onClick={handleLogout}>
            <div className={styles.logoutsvg}>
              <img src={logoutSvg} alt="logout" />
            </div>
            <p>Logga ut</p>
          </div>
        </div>
      </div>
    </>
  );
}

export default SideNav
