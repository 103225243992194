import React from 'react';
import { Link } from "react-router-dom";

const noop = () => {};

const Button = ({
  children,
  type = 'button',
  block = false,
  color,
  onClick = noop,
  size,
  to = false,
  disabled = false,
  className = ''
}) => {
  const classes = [
    'rounded-full',
    'text-center',
    'font-medium',
    'text-base',
    'hover:opacity-50',
    'mr-2',
    className
  ];

  if (size === "big") {
    classes.push('p-4');
  } else {
    classes.push('px-4');
    classes.push('py-2');
  }

  if (block) {
    classes.push('block');
    classes.push('w-full');
  }
  if (color === 'primary') {
    classes.push('text-white');
    classes.push('bg-blue');
  }
  if (color === 'blue-dark') {
    classes.push('bg-blue-dark');
    classes.push('text-white');
  }
  if (color === 'secondary') {
    classes.push('bg-white');
    classes.push('text-black');
    classes.push('drop-shadow border-gray-100 border');
  }

  if (to !== false) {
    return (
      <Link
        className={classes.join(' ')}
        to={to}
      >
        {children}
      </Link>
    )
  }

  return (
    <button
      type={type}
      className={classes.join(' ')}
      onClick={onClick}
      disabled={disabled}
    >
      {children}
    </button>
  );
}

  /* .forminput > button[type="submit"] {
    background-color: #083EA7;
    border: none;
    color: white;
    padding: 0.85rem 2rem;
    text-align: center;
    text-decoration: none;
    display: inline-block;
    font-size: 1rem;
    margin: 14px 1px;
    cursor: pointer;
    box-shadow: 0px 10px 15px rgba(0, 0, 0, 0.1), 0px 4px 6px rgba(0, 0, 0, 0.05);
    border-radius: 100px;
    width: 100%;
    outline: none;
  } */


export default Button;
