import React from 'react'
import { useParams, NavLink } from "react-router-dom";
import styles from './nav.module.css';
import { useSelector } from "react-redux";

const Link = ({ to, label, ...props }) => (
  <NavLink to={to} activeClassName={styles.active} {...props}>{label}</NavLink>
)

const Nav = () => {
  const { clientID } = useParams();
  const pro = useSelector(({ user: { pro } }) => pro);

  return (
    <div className={styles.header}>
      <div className={styles.nav}>
        <Link to={`/patientoversikt/${clientID}`} label="Resultatöversikt" />
        <Link to={`/matningar/${clientID}`} label="Resultat & Bilder" />
        <Link to={`/schedule/${clientID}`} label="Blåsschema" />
        <Link to={`/patientinformation/${clientID}`} label="Klientinställningar" />
        {
          pro
          && (
            <>
              {false && <Link to={`/calendar/${clientID}`} label="Kalender" />}
              <Link to={{ pathname: "https://helloworld.vtest.esante.se/" }} target="_blank" label="Video" />
            </>
          )}
      </div>
    </div>
  )
}

export default Nav;
