import React from 'react'

function LoaderSuspense() {
    return (
        <div>
            <p>Loading...</p>
        </div>
    )
}

export default LoaderSuspense
