
let eventGuid = 0
let todayStr = new Date().toISOString().replace(/T.*$/, '') // YYYY-MM-DD of today

export const INITIAL_EVENTS = [
  {
    id: createEventId(),
    title: 'Gruppmöte',
    start: '2021-05-11 14:00',
    end: '2021-05-11 15:30',
    description: 'Framtid, hur går vi vidare?',
    result: '0.45'
  },
  {
    id: createEventId(),
    title: 'Möte med Behandlare',
    start: '2021-05-14 08:00',
    end: '2021-05-14 10:00',
    description: 'Uppföljning.. ',
    result: '0.45'
  },
  {
    id: createEventId(),
    title: 'Albus möte',
    start: todayStr + 'T16:30:00',
    end: todayStr + '17:00:00',
    description: '',
    result: '0.23',
    eventColor: 'pink'
  },
  {
    id: createEventId(),
    title: 'AA-möte',
    start: '2021-05-12 16:00',
    end: '2021-05-12 17:30',
    //color: '#083EA7',
    description: 'På Pärsgården. Fysisktmöte',
    result: '0.12',
    //editable: false
  },
]

export function createEventId() {
  return String(eventGuid++)
}
