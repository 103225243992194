import React, { useState } from 'react';
import RDP, { registerLocale } from "react-datepicker";
import sv from "date-fns/locale/sv";
import moment from "moment";
import styles from './Schedule.module.css'

registerLocale('sv', sv);

const DatePicker = ({
  name,
  label,
  altLabel,
  value,
  className,
  onChange,
  disabled,
  alt,
}) => {
  const [autoFocus, setAutoFocus] = useState(false);
  const [hide, setHide] = useState(alt);
  const toggle = () => {
    if (hide) {
      const diff = moment(value).diff(moment(), 'years');
      if (diff > 2) {
        onChange(moment(value).subtract(diff - 1, 'years').format('YYYY-MM-DD'));
      }
    }
    setHide(!hide);
    setAutoFocus(true);
  }

  const selected = moment(value).toDate();

  return (
    <div className="flex flex-row items-center">
      <label className="pr-2">{label}</label>

      {
        !disabled
        && (
          <>
            {
              !hide
                ? (
                  <div>
                    <RDP 
                      name={name}
                      value={value}
                      selected={selected}
                      onChange={onChange} 
                      dateFormat="yyyy-MM-dd"
                      disabled={disabled}
                      isClearable={false}
                      className={styles.ssEdit}
                      locale="sv"
                      showYearDropdown
                      showWeekNumbers
                      autoFocus={autoFocus}
                    />
                  </div>
                ) : (
                  <span className={styles.ssEdit} onClick={toggle}>
                    {altLabel}
                  </span>
                )
            }
          </>
        )
      }

      {
        disabled
        && (
          <span className={className}>
            {( alt ? altLabel : value )}
          </span>
        )
      }
    </div>
  );
};

export default DatePicker;
