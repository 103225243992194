import Tooltip from '@material-ui/core/Tooltip';
import InfoOutlinedIcon from '@material-ui/icons/InfoOutlined';
import React from 'react';
import { useSelector } from 'react-redux';
import { useWindowHeight } from '@react-hook/window-size';

import { signalsSelector } from '@redux/patient';
import Signals from './Signals/Signals';
import styles from './SignalsTable.module.css';

export const SignalsTable = (props) => {
  const signals = useSelector(signalsSelector);
  const height = useWindowHeight();

  return (
    <div style={{ height: `${height - 385}px`, overflow: 'scroll' }}>
      <table className={styles.signalTable}>
        <thead>
          <tr>
            <th>
              <div className={styles.flex}>
                <p>Signal</p>
                <Tooltip
                  title={
                    <div>
                      <p>
                        Grön = Resultat under promillegränsen
                      </p>
                      <p>Gul = Missat blåstest</p>
                      <p>Röd = Resultat över promillegränsen</p>
                      <p>Offline-symbol = Mätningen har gjorts utan internet och laddats upp när klienten återfått täckning</p>
                    </div>
                  }
                >
                  <InfoOutlinedIcon style={{ color: 'blue' }} />
                </Tooltip>
              </div>
            </th>
            <th>Resultat</th>
            <th>Beskrivning</th>
            <th>Tidpunkt</th>
            <th>Promillegräns</th>
            <th>Status</th>
          </tr>
        </thead>
        <tbody>
          {signals.signals.map((sig) => (
            <Signals key={sig.signalId} sig={sig} />
          ))}
        </tbody>
      </table>
    </div>
  );
};

export default SignalsTable;
