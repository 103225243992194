import React from 'react';
import Modal from 'react-modal';
import CircularProgress from '@material-ui/core/CircularProgress';
import styles from './ControlPanel/ControlPanel.module.css';
import Button from './Button';

const customStyles = {
  content: {
    top: '50%',
    left: '50%',
    right: 'auto',
    bottom: 'auto',
    marginRight: '-50%',
    transform: 'translate(-50%, -50%)',
  },
};

Modal.setAppElement('#root');

const CrasModal = ({
  modalOpen,
  label,
  content,
  onConfirm,
  onCancel,
  confirmLabel,
  cancelLabel,
  loading,
}) => (
  <Modal isOpen={modalOpen} style={customStyles} contentLabel="Example Modal">
    <p className={styles.modalHead}>{label}</p>
    {content}
    <div className={styles.btnModalCon}>
      {onConfirm && (
        <Button color="primary" onClick={onConfirm}>
          {loading ? (
            <CircularProgress
              style={{ marginTop: 4, color: '#fff' }}
              size={20}
            />
          ) : (
            confirmLabel
          )}
        </Button>
      )}
      {onCancel && (
        <Button color="primary" onClick={onCancel}>
          {loading ? (
            <CircularProgress
              style={{ marginTop: 4, color: '#fff' }}
              size={20}
            />
          ) : (
            cancelLabel
          )}
        </Button>
      )}
    </div>
  </Modal>
);

export default CrasModal;
