import Switch from '@material-ui/core/Switch';
import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';
import {
  breathCountSelector,
  brethSelector,
  getbreathalizerResults,
} from '../../redux/patient';
import MeasureGrid from './MeasureGrid';
import styles from './MeasureItem.module.css';
//const months = ["January","Februari","March","April","May","June","July","August","September","October","November","December"];

const Measurements = () => {
  const dispatch = useDispatch();
  const { clientID } = useParams();
  const patient = useSelector(
    ({ user: { clients } }) =>
      clients.filter(({ id }) => `${id}` === clientID)[0]
  );
  const breathCount = useSelector(breathCountSelector);
  const breth = useSelector(brethSelector);
  const [isLoading, setIsLoading] = useState(true);
  const [images, setImages] = useState(true);
  // const [breathResults, setBreathResults ] = useState([]);
  const [offset, setOffset] = useState(0);
  const limit = 15;
  const d = new Date();

  useEffect(() => {
    if (!patient) return;

    setIsLoading(true);

    dispatch(
      getbreathalizerResults({
        targetUser: patient.id,
        limit,
        offset,
        images,
      })
    )
      .then(() => {
        setIsLoading(false);
      })
      .catch((err) => {
        setIsLoading(false);
      });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [offset, limit, images, patient]);

  if (!patient) return null;

  const { firstname, lastname, usercode } = patient;

  return (
    <div className={styles.container}>
      <div className={styles.top}>
        <h2>Resultat & bilder</h2>
        <p>
          {firstname} {lastname} ({usercode.toUpperCase()})
        </p>
      </div>
      <div className={styles.topSelect}>
        <h3>{d.getFullYear()}</h3>
        <div className={styles.topright}>
          <button
            className={styles.btnMonth}
            onClick={() => setOffset(Math.max(offset - limit, 0))}
          >
            Framåt
          </button>
          <button
            disabled={offset + limit > breathCount}
            className={styles.btnMonth}
            onClick={() =>
              setOffset(offset + limit)
            }
          >
            Bakåt
          </button>
          {images ? <p>Dölj bilder</p> : <p>Visa bilder</p>}
          <Switch
            checked={images}
            onChange={() => setImages(!images)}
            color="primary"
            name="checkedB"
            inputProps={{ 'aria-label': 'primary checkbox' }}
          />
        </div>
      </div>
      <MeasureGrid isLoading={isLoading} BreathalizerResult={breth} />
    </div>
  );
};

export default Measurements;
