import React from 'react'
import styles from './ModuleCSS/Message.module.css'

function Message() {
    return (
        <div className={styles.container}>
            <div className={styles.top}>
                <h2>Meddelande</h2>
                <p>Matilda Nilsson (MANI)</p>
            </div>
            <div className={styles.messageTop}>
                <div className={styles.item1}>Meddelandemallar</div>
                <div>Schemalagda meddelanden</div>
                <div>Meddelandehistorik</div>
            </div>
            <div className={styles.messageBox}>

            </div>
        </div>
    )
}

export default Message
