import Snackbar from '@material-ui/core/Snackbar';
import { Alert, AlertTitle } from '@material-ui/lab';
import Button from '@mui/material/Button';
import Stack from '@mui/material/Stack';
import { readAllSignal, signalsSelector } from '@redux/patient';
import { clientSelector } from '@redux/user';
import React, { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';
import ExportPdf from './ExportPdf/ExportPdf';

const SignalsTool = ({refetch, filter}) => {
  const dispatch = useDispatch();
  const { clientID } = useParams();
  const patient = useSelector(clientSelector(clientID));
  const signals = useSelector(signalsSelector);

  const [resultOfMarked, setResultOfMarked] = useState({});
  const [open, setOpen] = useState(false);

  const handleReadAllSignal = () => {
    dispatch(
      readAllSignal({
        targetUser: patient.id,
        signalId: signals.signals?.[0]?.signalId,
      })
    ).then(({ payload }) => {
      setResultOfMarked(payload.data);
      setOpen(true);
      refetch();
    });
  };
  return (
    <Stack direction="row" spacing={2}>
      <Button variant="contained" style={{ borderRadius: 100 }} onClick={handleReadAllSignal}>
        Markera allt som läst
      </Button>
      <ExportPdf startTime={filter.startTime} endTime={filter.endTime}></ExportPdf>
      <Snackbar
        open={open}
        autoHideDuration={4000}
        onClose={() => {
          setOpen(false);
        }}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'right',
        }}
      >
        <Alert severity="success">
          <AlertTitle>Signaler har blivit lästa!</AlertTitle>
          <strong>{resultOfMarked.NrEffectedRows}</strong> signaler har
          markerats som läst
        </Alert>
      </Snackbar>
    </Stack>
  );
};

export default SignalsTool;
