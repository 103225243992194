import { createSlice } from '@reduxjs/toolkit';
import axios from 'axios';


const inituser = {
    appMode: "",
    clientID: "",
    customerId: 0,
    email: "oscar@esante.se",
    firstname: "Oskar",
    gdprAccept: false,
    groups: [],
    id: 1,
    lastname: "Zelin",
    msisdn: "",
    numberOfPushReceivers: 1,
    password: "hidden..",
    periods: [],
    ppmLimit: "0.00",
    ppmVisible: "false",
    smsLevel: "",
    smsRecipients: "",
    spontaneousBAC: "true",
    supportMsisdn: "",
    supportName: "",
    therapistMsisdn: "",
    therapistName: "Albus",
    type: "therapist",
    usercode: "oscar.selin",
    username: "oscar.selin"
}
const empty = {
    appMode: "",
    clientID: "",
    customerId: null,
    email: "",
    firstname: "",
    gdprAccept: false,
    groups: [],
    id: 0,
    lastname: "",
    msisdn: "",
    numberOfPushReceivers: 1,
    password: "hidden..",
    periods: [],
    ppmLimit: "0.00",
    ppmVisible: "false",
    smsLevel: "",
    smsRecipients: "",
    spontaneousBAC: "true",
    supportMsisdn: "",
    supportName: "",
    therapistMsisdn: "",
    therapistName: "",
    type: "therapist",
    usercode: "",
    username: ""
}

export const therapistSlice = createSlice({
  name: 'therapist',
  initialState: {
    user: inituser,
  },
  reducers: {
    changeFirstName: (state, action) => {
      state.user.firstname = action.payload;
    },
    logout : state => {
        state.user = empty;
    },
    newUser : (state, action) => {
      state.user = action.payload;
    },
    updateUser : (state, action) => {
      state.user.firstname = action.payload.firstname;
      state.user.lastname = action.payload.lastname;
    },
    updateUseername : (state, action) => {
      state.user.username = action.payload;
    }
  },
});

export const { changeFirstName, logout, newUser, updateUser, updateUseername } = therapistSlice.actions;

// The function below is called a thunk and allows us to perform async logic. It
// can be dispatched like a regular action: `dispatch(incrementAsync(10))`. This
// will call the thunk with the `dispatch` function as the first argument. Async
// code can then be executed and other actions can be dispatched
/*
export const incrementAsync = amount => dispatch => {
  setTimeout(() => {
    dispatch(incrementByAmount(amount));
  }, 1000);
};
*/
// The function below is called a selector and allows us to select a value from
// the state. Selectors can also be defined inline where they're used instead of
// in the slice file. For example: `useSelector((state) => state.counter.value)`
//export const selectCount = state => state.counter.value;

export default therapistSlice.reducer;

export const logOutTherapist = () => async dispatch => {
  try {
      // await api.post('/api/auth/logout/') TODO
      dispatch(logout())
      console.log('-logOut therapist-');
  } catch(err) {
      console.log(err)
  }
}

export const updateTherapist = (data) => async dispatch => {
  try {
      const result = await axios.post(`/api/therapist/updatetherapist?firstname=${data.firstname}&lastname=${data.lastname}&email=${data.email}`)
      dispatch(updateUser(data))
      console.log('-updateUser-');
      dispatch(updateUseername(result.data));
      console.log(result.data);
      console.log('-new User Code-');
  } catch(err) {
      console.log(err)
  }
}
