import logo_albus from '@images/logo_albus_login.png';
import { forgotPassword } from '@redux/user';
import { useForm } from 'react-hook-form';
import { useDispatch } from 'react-redux';
import { Link, useHistory } from 'react-router-dom';
import Button from '../../components/Button';
import styles from '../login.module.css';

function ForgotPassword() {
  const history = useHistory();
  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm();

  const dispatch = useDispatch();

  const onError = (err) => {
    alert(
      'Det verkar inte finnas något konto med dessa uppgifter. Vänligen kontrollera uppgifterna eller kontakta Albus support för hjälp'
    );
  };

  const onSubmit = (data) => {
    dispatch(forgotPassword(data))
      .then((d) => {
        if (d?.error) {
          return onError();
        }
        history.push('/');
      }).catch(onError);
  };

  return (
    <div className={styles.container}>
      <div className={styles.box} style={{ height: '400px' }}>
        <div className={styles.form}>
          <div style={{ width: 150 }}>
            <img src={logo_albus} alt="logo" />
          </div>
          <form className={styles.forminput} onSubmit={handleSubmit(onSubmit)}>
            <div className="text-center">
              <span>
                Fyll i din email kopplat till kontot eller ditt användarnamn.
              </span>
            </div>
            <input
              type="text"
              placeholder="Användarnamn"
              {...register('username', { required: true })}
            />
            {errors.username && errors.username.type === 'required' && (
              <span>Du måste fylla i detta fällt</span>
            )}
            <Button
              className="mt-15px"
              type="submit"
              block={true}
              color="primary"
              size="big"
            >
              Skicka nytt lösenord
            </Button>

            <div className={styles.txt_link + ' text-center mt-5'}>
              <Link to="/">Logga in</Link>
            </div>
          </form>
        </div>
      </div>
    </div>
  );
}

export default ForgotPassword;
