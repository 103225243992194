import React, { useState, useRef } from 'react'
import styles from './calendar.module.css'
import { useParams } from "react-router-dom";
import { useSelector } from 'react-redux';
import FullCalendar, { formatDate } from '@fullcalendar/react'
import dayGridPlugin from '@fullcalendar/daygrid'
import timeGridPlugin from '@fullcalendar/timegrid'
import interactionPlugin from '@fullcalendar/interaction'
import svLocale from '@fullcalendar/core/locales/sv';
import { INITIAL_EVENTS, createEventId } from './event-utils'
//import signalUnderPpmLimit from '../../images/signal-underPpmLimit.png'
//import signalMissedScheduleItem from '../../images/signal-missedScheduleItem.png'
//import signalAbovePpmLimit from '../../images/signal-abovePpmLimit.png'
//import IconButton from '@material-ui/core/IconButton';
//import AddCircleIcon from '@material-ui/icons/AddCircle';
//import CustomizedDialogs from './materialUi.js'
import bin from '../../svg/bin 1.svg'

const Calendar = () => {
  const { clientID } = useParams();
  const patient = useSelector(({ user: { clients } }) => clients.filter(({ id }) => `${id}` === clientID)[0]);
  const [ weekendsVisible, setWeekendsVisable ] = useState(true);
  const [ currentEvents, setCurrentEvents ] = useState([]);
  //const [ showForm, setShowForm ] = useState(false)
  const calendarRef = useRef();
 
  const handleWeekendsToggle = () => {
    setWeekendsVisable(!weekendsVisible)
  }

  const handleEventClick = (clickInfo) => {
    let newTitle = prompt('Ändra namn på aktiviteten: ', clickInfo.event.title)
    let desc = prompt('Beskrivning: ', clickInfo.event.extendedProps.description)
    //clickInfo.event.moveEnd('10:13')
    clickInfo.event.setProp('title', newTitle)
    clickInfo.event.setExtendedProp('description', desc)
    /*
    if (window.confirm(`Are you sure you want to delete the event '${clickInfo.event.title}'`)) {
      clickInfo.event.remove()
    }*/
  }

const handleEvents = (events) => {
  console.log('--handleEvents--');
  setCurrentEvents(events);
}

const handleDateSelect = (selectInfo) => {
  let title = prompt('Nytt aktivitet')
  let calendarApi = selectInfo.view.calendar

  calendarApi.unselect() // clear date selection

  if (title) {
    calendarApi.addEvent({
      id: createEventId(),
      title,
      start: selectInfo.startStr,
      //end: selectInfo.endStr + "T19:00:00",
      //allDay: selectInfo.allDay
    })
  }
}

const newEvent = () => {
  let calendarApi = calendarRef.current.getApi()
  let title = prompt('Please enter a new title for your event')
  console.log('---tilte---')
  console.log(title);
  calendarApi.unselect() // clear date selection
  if (title) {
    let nEvent = {
      id: createEventId(),
      title: title,
      start: '2021-05-11 20:00',
      end: '2021-05-11 21:30'
    };
    console.log(nEvent);
    calendarApi.addEvent(nEvent)
  }
  //setShowForm(false);
}

const delet = () => {
  const calendarApi = calendarRef.current.getApi()
  calendarApi.removeAllEvents();
}
/*
const EventForm = () => {
  return(
    <>
      <h3>Skriv in ditt möte</h3>
      <p>Tid å datum</p>
      <button onClick={delet}>newEvent</button>
    </>
  )
}
*/
const RenderSidebar = () => (
  <div className={styles.demoappsidebar}>
    <div className={styles.demoappsidebarsection}>
      <h3>Instruktioner</h3>
      <ul>
        <li>Klicka på ett datum för att skapa en ny aktivitet</li>
        <li>Du kan dra och ändra aktivitet</li>
        <li>Klicka på aktiviten för att ta bort det</li>
      </ul>
    </div>
    
    <div className={styles.demoappsidebarsection}>
      <label>
        <input
          type='checkbox'
          checked={weekendsVisible}
          onChange={handleWeekendsToggle}
        ></input>
        Visa helger
      </label>
    </div>
    <div className={styles.demoappsidebarsection}>
      <h3>Alla aktiviteter ({currentEvents.length})</h3>
      <ul>
        {currentEvents.map(renderSidebarEvent)}
      </ul>
    </div>
    <button className={styles.btnSend} onClick={delet}>Ta bort all aktiviteter</button>
  </div>
)

if (!patient) return null;

    return (
      <div className={styles.container}>
            <div className={styles.top}>
                <h2>Kalender</h2>
                <p>{patient.firstname} {patient.lastname} ({patient.usercode.toUpperCase()})</p>
            </div>
            <div className={styles.demoapp}>
              
              <RenderSidebar />
              <div className={styles.demoappmain}>
                <FullCalendar
                  ref={calendarRef}
                  plugins={[dayGridPlugin, timeGridPlugin, interactionPlugin]}
                  headerToolbar={{
                    left: 'prev,next today',
                    center: 'title',
                    right: 'dayGridMonth,timeGridWeek,timeGridDay'
                  }}
                  footerToolbar={{
                    left: 'prev,next',
                    center: 'newEvent',
                    right: 'deletAll'
                  }}
                  initialView='timeGridWeek'
                  editable={true}
                  selectable={true}
                  selectMirror={true}
                  dayMaxEvents={true}
                  weekends={weekendsVisible}
                  initialEvents={INITIAL_EVENTS} // alternatively, use the `events` setting to fetch from a feed
                  select={handleDateSelect}
                  eventContent={renderEventContent} // custom render function
                  eventClick={handleEventClick}
                  eventsSet={handleEvents} // called after events are initialized/added/changed/removed
                  /* you can update a remote database when these fire:
                  eventAdd={function(){}}
                  eventChange={function(){}}
                  eventRemove={function(){}}
                  */
                  locale={svLocale}
                  weekNumbers={true}
                  navLinks={true}
                  //contentHeight={600}
                  //height={650}
                  eventColor={'#083EA7'}
                  customButtons={{
                    deletAll: {
                      text: 'Ta bort alla aktiviteter',
                      click: delet
                    },
                    newEvent: {
                      text: 'Ny aktivitet',
                      click: newEvent
                    },
                  }}
                  //slotDuration={'00:15:00'}
                  //slotLabelInterval={'00:15:00'}
                  
                />
              </div>
            </div>
      </div>
    )
  }

function renderEventContent(eventInfo) {
  return (
    <>
    <div className={styles.eventCon}>
      <b>{eventInfo.timeText}</b>
      <i className={styles.title}> {eventInfo.event.title}</i>
      <i className={styles.description}> {eventInfo.event.extendedProps.description}</i>
      {/* <i>{eventInfo.event.extendedProps.result} ‰</i> */}
      {/* false && <img className={styles.symbol} src={signalAbovePpmLimit} alt='symbol' />*/}
    </div>
    </>
  )
}

function renderSidebarEvent(event) {
  return (
    <li key={event.id} >
      <b><span>
        <img style={{height: 15, width: 15}} src={bin} onClick={() => event.remove()} alt='Bin' />
        </span>{formatDate(event.start, {year: 'numeric', month: 'short', day: 'numeric', locale: 'sv'})}</b>
      <i className={styles.title}> {event.title}</i>
      <i className={styles.description}> {event.extendedProps.description}</i>
      
    </li>
  )
}

export default Calendar