import React, { useState } from 'react';
import styles from './header.module.css';
import logoAlbus from '@images/logo_white _albus_horizontal.png'
import LetterAvatars from './materialUIComp/LetterAvatars'
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import LanguageIcon from '@material-ui/icons/Language';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import IconButton from '@mui/material/IconButton';
import Tooltip from '@mui/material/Tooltip';
import { useTranslation } from "react-i18next";
import { useHistory } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";
import { logout } from '../redux/user';

export default function HeadCras() {
  const [anchorEl, setAnchorEl] = useState(null);
  const [anchorLANG, setAnchorLANG] = useState(null);
  const { t, i18n } = useTranslation();
  const history = useHistory();
  const user = useSelector(({ user: { self } }) => self);
  const dispatch = useDispatch();
  const lngi18n = false;

  const changeLanguage = lng => {
    i18n.changeLanguage(lng);
    handleCloseLANG();
  };

  const handleClickLANG = (event) => {
    setAnchorLANG(event.currentTarget);
  };

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleLogout = () => {
    setAnchorEl(null);
    console.log(logout);
    dispatch(logout());
    history.push('/');
  }

  const handleCloseLANG = () => {
    setAnchorLANG(null);
  };

  const toHomePage = () => {
    history.push('/');
  }

  return (
    <div className={styles.header}>
      <div className={styles.logo} onClick={toHomePage} >
        <img src={logoAlbus} alt="albus" style={{width: '95px'}}/>
      </div>
      <div className={styles.cras}></div>
      {
        user !== null
        && (
          <div className={styles.right}>
            <LetterAvatars color="secundary" firstName={user.firstname} lastName={user.lastname} />
            <div className={styles.therapist} >
              <p>{user.firstname} {user.lastname}</p>
            </div>

            <Tooltip title="Din profil">
              <IconButton onClick={handleClick} color="primary">
                <ExpandMoreIcon style={{ color: "white" }} />
              </IconButton>
            </Tooltip>

            <Menu
              id="simple-menu"
              anchorEl={anchorEl}
              keepMounted
              open={Boolean(anchorEl)}
              onClose={handleClose}
            >
              <MenuItem onClick={() => history.push('/userprofile')}>{t('Profile')}</MenuItem>
              <MenuItem onClick={handleLogout}>{t('logout')}</MenuItem>
            </Menu>
            {lngi18n &&
              (
                <>
                  <Tooltip title={t('Change Lang')}>
                    <IconButton onClick={handleClickLANG} color="primary">
                      <LanguageIcon style={{ color: "white" }} />
                    </IconButton>
                  </Tooltip>

                  <Menu
                    id="langue-menu"
                    anchorEl={anchorLANG}
                    keepMounted
                    open={Boolean(anchorLANG)}
                    onClose={handleCloseLANG}
                  >
                    <MenuItem onClick={() => changeLanguage('en')}>en</MenuItem>
                    <MenuItem onClick={() => changeLanguage('sv')}>sv</MenuItem>
                    <MenuItem onClick={() => changeLanguage('nk')}>nk</MenuItem>
                    <MenuItem onClick={() => changeLanguage('dk')}>dk</MenuItem>
                  </Menu>
                </>
              )}
          </div>
        )
      }
    </div>
  );
};
