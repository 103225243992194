import TextField from '@material-ui/core/TextField';
import { createTransfer } from '@redux/user-transfer';
import { Controller, useForm } from 'react-hook-form';
import { useDispatch } from 'react-redux';

export const PatientTranserForm = ({ id, userId, onSuccess }) => {
  const dispatch = useDispatch();
  const {
    watch,
    handleSubmit,
    control,
    setError,
    formState: { errors },
  } = useForm({
    defaultValues: {
      username: '',
    },
  });

  const onSubmit = async ({ username }) => {
    const result = await dispatch(
      createTransfer({ userId, toTherapistName: username })
    );
    if (result.error) {
      setError('username', {
        message:
          'Vi kan tyvärr inte hitta användaren. Var god kontrollera användarnamnet.',
      });
    } else {
      alert(
        `Din förfrågan har skickats! Klienten kommer att flyttas när ${username} accepterat din förfrågan.`
      );
      onSuccess && onSuccess();
    }
  };

  watch('username', () => {
    setError('username', {});
  });

  return (
    <form id={id} onSubmit={handleSubmit(onSubmit)} style={{ width: '485px' }}>
      <p>Skriv in användarnamnet på den admin du vill flytta klienten till</p>
      <Controller
        name={'username'}
        control={control}
        render={({ field: { onChange, value } }) => (
          <TextField
            autoFocus
            margin="dense"
            fullWidth
            variant="standard"
            onChange={onChange}
            value={value}
            label={'Användarnamnet'}
          />
        )}
      />
      {errors?.username && (
        <span className="error-helper">{errors?.username.message}</span>
      )}
    </form>
  );
};
