import React, { useState } from 'react';
import { useSelector, useDispatch } from "react-redux";
import { terminate } from '../../redux/user';
import { fetchClients, fetchClientsEnded } from '../../redux/user';
import TableRow from './tableRow';
import Modal from '../Modal';
import styles from './ControlPanel.module.css';

const labelModal = `Om du avslutar klienten inaktiveras alla aktiva blåsscheman och kontot hamnar
  under Avslutade klienter. Information om hur du returnerar klientens alkomätare
  hittar du under fliken Support.`

let contentModal = "";
function ActiveTable() {
  const dispatch = useDispatch();
  const clients = useSelector(({ user: clients }) => clients);
  const [modalOpen, setModalOpen] = useState(false);
  const [userId, setUserId] = useState();
  const [loading, setLoading] = useState(false);

  const confirm = async () => {
    setLoading(true);
    await dispatch(terminate({ userId }))
      .then(() => {
        dispatch(fetchClients());
        dispatch(fetchClientsEnded());
        setModalOpen(false);
      })
    setLoading(false);
  }
  const cancel = () => {
    setModalOpen(false);
  }

  const terminateModal = ({ firstname, lastname, id }) => {
    setModalOpen(true);
    setUserId(id);
    contentModal = <p className={styles.name}>Vill du avsluta <span style={{ fontStyle: "italic" }}>{firstname} {lastname}</span></p>;
  }
  return (
    <div>
      <table className={styles.activeTable}>
        <thead>
          <tr>
            <th>Namn</th>
            <th>Albus-ID</th>
            <th>Eget ID</th>
            <th>Aktivt Schema</th>
            <th>Olästa</th>
            {false && <th>Behandlingar</th>}
            <th></th>
          </tr>
        </thead>
        <tbody>
          <TableRow terminateModal={terminateModal} {...clients} />
        </tbody>
      </table>
      <Modal
        modalOpen={modalOpen}
        label={labelModal}
        content={contentModal}
        onConfirm={confirm}
        onCancel={cancel}
        confirmLabel={'Ja'}
        cancelLabel={'Avbryt'}
        iconColor={'orange'}
        loading={loading}
      />
    </div>

  );
}

export default ActiveTable
