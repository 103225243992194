import { useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';
import { clientSelector } from '../../redux/user';
import { signalsSelector } from '../../redux/patient';
import styles from './PatientOverview.module.css';
import PatientSignals from './PatientSignals/PatientSignals';
import { SignalSum } from "@components/ControlPanel/tableRow"

function PatientOverview() {
  const { clientID } = useParams();
  const patient = useSelector(clientSelector(clientID));
  const signals = useSelector(signalsSelector);

  if (!patient) return null;
  const { firstname, lastname, usercode } = patient;
  const { signalSummary } = signals;

  return (
    <div className={styles.container}>
      <div className={styles.top}>
        <SignalSum signalSummary={signalSummary} />
        <p style={{ fontWeight: 500, fontSize: '1.5rem' }}>
          {firstname} {lastname} ({usercode.toUpperCase()})
        </p>
      </div>
      <div className={styles.schema}>
        <PatientSignals patient={patient} />
      </div>
    </div>
  );
}

export default PatientOverview;
