import Avatar from '@material-ui/core/Avatar';
import { makeStyles } from '@material-ui/core/styles';
import Step from '@mui/material/Step';
import StepLabel from '@mui/material/StepLabel';
import Stepper from '@mui/material/Stepper';
import { styled } from '@mui/material/styles';
import StepConnector, { stepConnectorClasses } from '@mui/material/StepConnector';
import clsx from 'clsx';

const useStyles = makeStyles((theme) => ({
  step: {
    backgroundColor: theme.palette.grey[50],
    border: `2px solid #bdbdbd`,
    color: '#bdbdbd',
    width: '30px',
    height: '30px',
    fontSize: '14px',
  },
  stepActive: {
    border: `2px solid #083ea7`,
    color: '#083ea7',
  }
}));

const ColorlibConnector = styled(StepConnector)(({ theme }) => ({
  [`&.${stepConnectorClasses.alternativeLabel}`]: {
    padding: 0,
  },
  [`&.${stepConnectorClasses.active}`]: {
    [`& .${stepConnectorClasses.line}`]: {
      backgroundColor: '#bdbdbd'
    },
  },
  [`&.${stepConnectorClasses.completed}`]: {
    [`& .${stepConnectorClasses.line}`]: {
      backgroundColor: '#083ea7',
    },
  },
  [`& .${stepConnectorClasses.line}`]: {
    height: '2px',
    border: 0,
    backgroundColor: '#bdbdbd'
  },
}));

function StepIcon(props) {
  const { completed, icon } = props;
  const classes = useStyles();
  return <Avatar className={clsx(classes.step, completed && classes.stepActive)}>{icon}</Avatar>;
}

export const PatientStepper = (props) => {
  const steps = [1, 2, 3];
  return (
    <Stepper activeStep={props.activeStep} connector={<ColorlibConnector />} style={{maxWidth: '180px'}}>
      {steps.map((step) => {
        return (
          <Step  key={step} style={{padding: 0, width: '30px'}}>
            <StepLabel StepIconComponent={StepIcon} style={{padding: 0}}></StepLabel>
          </Step>
        );
      })}
    </Stepper>
  );
};
