import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import CloudDownloadOutlinedIcon from '@material-ui/icons/CloudDownloadOutlined';
import Button from '@mui/material/Button';
import { PDFDownloadLink } from '@react-pdf/renderer';
import { clientSelector } from '@redux/user';
import { useParams } from 'react-router-dom';
import Quixote from './signalPdf';

import { exportSignals, exportSignalsSelector } from '@redux/patient';

function ExportPdf({ startTime, endTime }) {
  const dispatch = useDispatch();
  const [creatingPDF, setCreatingPDF] = useState(false);
  const { clientID } = useParams();
  const patient = useSelector(clientSelector(clientID));
  const Pro = useSelector(({ user: { pro } }) => pro);
  const signals = useSelector(exportSignalsSelector);

  const handleExport = () => {
    setCreatingPDF(true);
    dispatch(
      exportSignals({
        targetUser: patient.id,
        startTime: startTime?.startOf('day').toDate(),
        endTime: endTime?.endOf('day').toDate(),
      })
    );
  };

  useEffect(() => {
    setCreatingPDF(false);
  }, [startTime, endTime]);

  return (
    <>
      {!creatingPDF && (
        <Button
          variant="contained"
          style={{ borderRadius: 100 }}
          startIcon={<CloudDownloadOutlinedIcon />}
          onClick={handleExport}
        >
          Skapa resultatrapport
        </Button>
      )}

      {creatingPDF && !signals && (
        <Button
          variant="contained"
          style={{ borderRadius: 100 }}
          startIcon={<CloudDownloadOutlinedIcon />}
        >
          Skapar resultatrapport..
        </Button>
      )}

      {creatingPDF && signals && (
        <PDFDownloadLink
          document={
            <Quixote
              nr={signals?.nr}
              signals={signals?.signals}
              signalSummary={signals?.signalSummary}
              patient={patient}
              Pro={Pro}
            />
          }
          fileName={`Resultatrapport_${patient.usercode.toUpperCase()}_${new Date().getSeconds()}.pdf`}
        >
          {({ loading, error }) => {
            console.log({ loading, error });

            const text = loading
              ? 'Skapar resultatrapport..'
              : 'Ladda ner resultatrapport';

            return (
              <Button
                variant="contained"
                style={{ borderRadius: 100, height: '100%' }}
                startIcon={<CloudDownloadOutlinedIcon />}
              >
                {text}
              </Button>
            );
          }}
        </PDFDownloadLink>
      )}
    </>
  );
}

export default ExportPdf;
