import AddPatient from '../components/addPatient';
import AnalysTest from '../components/analystest';
import Calendar from '../components/calendar/Calendar';
import ControlPanel from '../components/ControlPanel/ControlPanel';
import Documents from '../components/documents';
import Measurements from '../components/MeasurementCard/Measurements';
import Message from '../components/Message';
import Nav from '../components/Nav';
import Patientinformation from '../components/Patientinformation';
import PatientOverview from '../components/patientOverview/PatientOverview';
import Schedule from '../components/schedule';
import Shop from '../components/shop';
import SideNav from '../components/sideNav/SideNav';
import Strategies from '../components/Strategies';
import Support from '../components/Support';
import TherapistProfile from '../components/TherapistProfile';
import UserProfile from '../components/UserProfile';
import Home from './Home';
import styles from './pages.module.css';
import { useWindowHeight } from '@react-hook/window-size';

export function PageLayout({ sidenav, nav, children }) {
  const height = useWindowHeight() - 105;
  return (
    <div className={styles.container} style={{ height: `${height}px` }}>
      <div className={styles.sidenav}>{sidenav && <SideNav />}</div>
      <div className={styles.area}>
        {nav && (
          <div className={styles.nav}>
            <Nav />
          </div>
        )}

        <div className={styles.main}>{children}</div>
      </div>
    </div>
  );
}

export function HomePage() {
  return (
    <PageLayout sidenav={true}>
      <Home />
    </PageLayout>
  );
}

export function SupportPage() {
  return (
    <PageLayout sidenav={true}>
      <Support />
    </PageLayout>
  );
}

export function AnalystestPage() {
  return (
    <PageLayout sidenav={true} nav={true}>
      <AnalysTest />
    </PageLayout>
  );
}

export function PatientOverviewPage() {
  return (
    <PageLayout sidenav={true} nav={true}>
      <PatientOverview />
    </PageLayout>
  );
}

export function MeasurementsPage() {
  return (
    <PageLayout sidenav={true} nav={true}>
      <Measurements />
    </PageLayout>
  );
}

export function MessagePage() {
  return (
    <PageLayout sidenav={true} nav={true}>
      <Message />
    </PageLayout>
  );
}

export function StrategiesPage() {
  return (
    <PageLayout sidenav={true} nav={true}>
      <Strategies />
    </PageLayout>
  );
}

export function PatientinformationPage() {
  return (
    <PageLayout sidenav={true} nav={true}>
      <Patientinformation />
    </PageLayout>
  );
}

export function TherapistProfilePage() {
  return (
    <PageLayout sidenav={true} nav={false}>
      <TherapistProfile />
    </PageLayout>
  );
}

export function AddPatientPage() {
  return (
    <PageLayout sidenav={true} nav={false}>
      <AddPatient />
    </PageLayout>
  );
}

export function ControlPanelPage() {
  return (
    <PageLayout sidenav={true}>
      <ControlPanel />
    </PageLayout>
  );
}

export function SchedulePage() {
  return (
    <PageLayout sidenav={true} nav={true}>
      <Schedule />
    </PageLayout>
  );
}

export function DocumentsPage() {
  return (
    <PageLayout sidenav={true} nav={false}>
      <Documents />
    </PageLayout>
  );
}

export function ShopPage() {
  return (
    <PageLayout sidenav={true} nav={false}>
      <Shop />
    </PageLayout>
  );
}

export function UserProfilePage() {
  return (
    <PageLayout sidenav={true} nav={false}>
      <UserProfile />
    </PageLayout>
  );
}

export function CalendarPage() {
  return (
    <PageLayout sidenav={true} nav={true}>
      <Calendar />
    </PageLayout>
  );
}
