import { useEffect, useState } from 'react';
import { useHistory, useParams } from 'react-router-dom';

import styles from './Schedule.module.css';

import Button from '../Button';
import Schedules from './Schedules';

import { setExisting } from './utils';

const initialState = {};

const Edit = ({ update, schedules }) => {
  const [data, setData] = useState(initialState); // Kommer innehålla data som ska sparas
  const [loading, setLoading] = useState(false);
  const [schedule, setSchedule] = useState({});
  const { id } = useParams();
  const history = useHistory();
  const [error, setErr] = useState({});
  const [disable, setDisable] = useState(false);

  const reset = () => {
    console.log('reset');
    setData(initialState);
    setLoading(false);
  };

  const save = () => {
    setLoading(true);
    update(data).then((res) => {
      reset();
      history.goBack();
      console.log(res);
    });
  };

  const set = (opts) => setExisting({ setData, ...opts }, data);

  useEffect(() => {
    if (!schedules || schedules.length === 0) return null;

    const scheduleData = schedules.filter(
      ({ scheduleId }) => `${scheduleId}` === id
    );
    if (!scheduleData || scheduleData.length !== 1) return null;

    const [item] = scheduleData;

    setSchedule(item);
  }, [schedules, id]);

  useEffect(() => {
    console.log({ error, disable });

    if (Object.keys(error).length > 0) {
      setDisable(true);
    } else {
      setDisable(false);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [Object.keys(error), setErr]);

  if (!schedule) return null;
  if (loading) return null;
  if (!id) return null;

  return (
    <div className={styles.messageBox}>
      <Schedules
        schedules={[schedule]}
        set={set}
        data={data}
        slug={'overview'}
        disableInput={false}
        setErr={setErr}
        err={error}
      />
      <div className="flex justify-between">
        <Button color="primary" onClick={save} disabled={disable}>
          Spara
        </Button>
        {disable && (
          <p>
            Du behöver korrigera schemats rödmarkerade fält innan du kan spara
          </p>
        )}
      </div>
    </div>
  );
};

export default Edit;
