import { useState } from 'react';
import { useForm } from 'react-hook-form';
import { useDispatch } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { sendOrder } from '../redux/user';
import styles from './shop.module.css';

const Shop = () => {
  const history = useHistory();
  const {
    register,
    handleSubmit,
    reset,
    formState: { errors },
  } = useForm();
  const dispatch = useDispatch();
  const [step, setStep] = useState(0);
  const [resetBtn, setResetBtn] = useState(0);

  const next = () => {
    console.log('next step');
    setStep(1);
  };

  const backToForm = () => {
    setStep(0);
    setResetBtn(1);
  };

  const onSubmit = (data) => {
    dispatch(sendOrder(data)).then(next).catch(console.log);
  };

  return (
    <div className={styles.container}>
      <div className={styles.top}>
        <h2>Beställ alkomätare</h2>
      </div>
      {step === 0 && (
        <>
          <div className={styles.txtInfo}>
            <p>
              Beställ mätare till din arbetsplats eller direkt till klienten.
              Det ingår en alkomätare per aktiv klient. Vi erbjuder även ett
              kostnadsfritt beredskapslager av mätare - Kontakta supporten för
              mer information eller om du behöver beställa tillbehör.
            </p>
            <p>Normal leveranstid: 3-5 arbetsdagar.</p>
          </div>
          <h3>Leveransaddress</h3>
          <div className={styles.profile}>
            <form onSubmit={handleSubmit(onSubmit)}>
              <div className={styles.form}>
                <div className={styles.firstName}>
                  <label>FÖRETAGSNAMN</label>
                  <input
                    type="text"
                    {...register('customerName', { maxLength: 40 })}
                  />
                  {/*errors.customerName && errors.customerName.type === "required" && <span>Du måste fylla i ett Företagsnamn</span>*/}
                  {errors.customerName &&
                    errors.customerName.type === 'maxLength' && (
                      <span>Företagsnamnet är för långt!</span>
                    )}
                </div>
                <div className={styles.lastName}>
                  <label>MOTTAGARENS NAMN *</label>
                  <input
                    type="text"
                    {...register('contactName', {
                      required: true,
                      maxLength: 40,
                    })}
                  />
                  {errors.contactName &&
                    errors.contactName.type === 'required' && (
                      <span>Du måste fylla i ett kontaktnamn</span>
                    )}
                  {errors.contactName &&
                    errors.contactName.type === 'maxLength' && (
                      <span>Kontaktnamnet är för långt!</span>
                    )}
                </div>
                <div className={styles.phonenumber}>
                  <label>GATUADRESS *</label>
                  <input
                    type="text"
                    {...register('address', { required: true, maxLength: 40 })}
                  />
                  {errors.address && errors.address.type === 'required' && (
                    <span>Du måste fylla i en gatuadress</span>
                  )}
                  {errors.address && errors.address.type === 'maxLength' && (
                    <span>Gatuadressen är för långt!</span>
                  )}
                </div>

                <div className={styles.altpatientId}>
                  <label>ORT *</label>
                  <input
                    type="text"
                    {...register('city', { required: true, maxLength: 40 })}
                  />
                  {errors.city && errors.city.type === 'required' && (
                    <span>Du måste fylla i en stad</span>
                  )}
                  {errors.city && errors.city.type === 'maxLength' && (
                    <span>stadnamnet är för långt!</span>
                  )}
                </div>
                <div className={styles.contactPhonenumber}>
                  <div className={styles.flex}>
                    <label>POSTNUMMER *</label>
                  </div>
                  <input
                    type="text"
                    {...register('postalCode', {
                      required: true,
                      maxLength: 40,
                    })}
                  />
                  {errors.postalCode &&
                    errors.postalCode.type === 'required' && (
                      <span>Du måste fylla i ett postnummer</span>
                    )}
                  {errors.postalCode &&
                    errors.postalCode.type === 'maxLength' && (
                      <span>postnumret är för långt!</span>
                    )}
                </div>
                <div className={styles.signal}>
                  <div className={styles.flex}>
                    <label>ANTAL MÄTARE *</label>
                  </div>
                  <input
                    type="number"
                    {...register('quantity', { required: true })}
                    defaultValue={1}
                    placeholder="1"
                  ></input>
                  {errors.quantity && errors.quantity.type === 'required' && (
                    <span>Du måste fylla i minst en mätare</span>
                  )}
                </div>
              </div>
              <button className={styles.btnSend} type="submit">
                Skicka beställning
              </button>
              {resetBtn === 1 && (
                <button className={styles.btnSend} onClick={() => reset()}>
                  Töm formuläret
                </button>
              )}
            </form>
          </div>
        </>
      )}
      {step === 1 && (
        <>
          <h3>Din beställning är skickad!</h3>
          <div className={styles.txtInfo}>
            <p>Frakten tar normalt 2-5 arbetsdagar.</p>
          </div>
          <button className={styles.btnSend} onClick={backToForm}>
            Beställ fler mätare
          </button>
          <button
            className={styles.btnSend}
            onClick={() => history.push('/controlpanel/activepatient')}
          >
            Till översikt
          </button>
        </>
      )}
    </div>
  );
};

export default Shop;
