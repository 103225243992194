import React from 'react'
import styles from './ControlPanel.module.css'
import { useSelector } from "react-redux";
import TableRowEnded from './TableRowEnded';

const EndedTable = () => {
  const clients = useSelector(({ user: clients }) => clients);

  return (
    <table className={styles.endedTable}>
      <thead>
        <tr>
          <th>Klienter</th>
          <th>ID</th>
          <th>Aktivt Schema</th>
          <th></th>
        </tr>
      </thead>
      <tbody>
        <TableRowEnded {...clients} />
      </tbody>
    </table>
  );
}

export default EndedTable
