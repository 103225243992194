import React, { useEffect } from 'react'
import { useForm } from "react-hook-form";
import { useDispatch, useSelector } from "react-redux";
import { tokenRequest, requestToken } from '../redux/user';
import styles from './login.module.css';
import Button from '../components/Button';
import logo_albus from "@images/logo_albus_login.png"
import { Link } from "react-router-dom";

function LoginPage() {
  const { register, handleSubmit, formState: { errors } } = useForm();

  const dispatch = useDispatch();

  const code = useSelector(({ user: { code } }) => code);

  const onError = (err) => {
    alert('Fel användarnamn / lösenord');
  }

  const onSubmit = (data) => {
    dispatch(tokenRequest(data))
      .then((d) => console.log(d.payload.status))
      .catch(onError);
  }

  useEffect(() => {
    if (code === null) return;

    dispatch(requestToken(code))
      .then(console.log)
      .catch(onError);
  }, [code, dispatch]);

  return (
    <div className={styles.container}>
      <div className={styles.box}>
        <div className={styles.form}>
          <div style={{ width: 150 }}>
            <img src={logo_albus} alt="logo" />
          </div>
          <form className={styles.forminput} onSubmit={handleSubmit(onSubmit)}>
            <input type="text" placeholder="Användarnamn" {...register("username", { required: true })} />
            {errors.username && errors.username.type === "required" && <span>Du måste fylla i detta fällt</span>}
            <input type="password" placeholder="Lösenord" {...register("password", { required: true })} />
            {errors.password && errors.password.type === "required" && <span>Du måste fylla i ett lösenord</span>}
            <Button
              className="mt-15px"
              type="submit"
              block={true}
              color="primary"
              size="big"
            >
              Logga in
            </Button>
            {/* <div className={styles.txt_link + ' text-center mt-5'}>
              <Link to="/forgot-password">Glömt ditt lösenord?</Link>
            </div> */}
          </form>

          <p className={styles.txt}>
            Albus system är godkända medicintekniska produkter med CE-märkning.
            All data överförs krypterat och behandlas enligt personuppgiftslagen, patientdatalagen och EU:s dataskyddsförordning (GDPR).
          </p>

          <div className={styles.txt_link}>
            <a href="https://www.esante.se/sekretess-integritet" rel="noreferrer" target="_blank">Läs mer om vår datahantering</a>
            <a style={{ paddingLeft: 30 }} href="https://www.albuscare.com/" rel="noreferrer" target="_blank">Läs mer om Albus</a>
          </div>
        </div>
      </div>
    </div>
  )
}

export default LoginPage
