import moment from 'moment';
import { useEffect } from 'react';
import styles from './Schedule.module.css';

import Schedules from './Schedules';

const fallback = () => <p>Det finns inget inaktivt schema.</p>;

const Inactive = ({ refresh, schedules, clientID }) => {
  const check = moment().hour(0).minute(0).second(0).add('m', -1);
  const data = schedules.filter(({ scheduleEnd }) => {
    const end = moment(scheduleEnd);
    return check.isAfter(moment(end));
  });

  // eslint-disable-next-line react-hooks/exhaustive-deps
  useEffect(refresh, []);

  return (
    <div className={styles.messageBox}>
      <Schedules
        schedules={data}
        slug={'inactive'}
        disableInput={true}
        fallback={fallback}
        refresh={refresh}
        userId={clientID}
      />
    </div>
  );
};

export default Inactive;
