import React, { useState, useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { Link, useHistory, useParams } from "react-router-dom";

import styles from './Schedule.module.css'

import Schedules from './Schedules';
import { newScheduleData, scheduleData_3_items as scheduleData } from './template'

import { setExisting } from './utils';
import { newSchedule } from "../../redux/user";

const Create = ({ clientID, schedules: templates }) => {
  const [schedules, setSchedules] = useState([]);
  const [data, setData] = useState({}); // Kommer innehålla data som ska sparas
  const dispatch = useDispatch();
  const history = useHistory();
  const { template_id } = useParams();

  const set = (opts) => {
    console.log({ opts });
    setExisting({ setData, ...opts }, data);
  }

  const create = () => {
    console.log('Create', data);

    const schedule = data[0];
    const { start, stop, create, sname } = schedule;

    const payload = {
      scheduleStart: start,
      scheduleEnd: stop,
      scheduleUserId: clientID,
      scheduleName: sname,
      scheduleItems: [],
    }

    for (let i = 0; i < create.length; i++) {
      if (create[i] !== null) {
        const data = {
          scheduleItemDayStart: create[i].scheduleItemDayStart,
          scheduleItemDayEnd: create[i].scheduleItemDayEnd,
          scheduleItemTimeStart: create[i].scheduleItemTimeStart,
          scheduleItemTimeEnd: create[i].scheduleItemTimeEnd,
        }
        payload.scheduleItems.push(data);
      }
    }

    dispatch(newSchedule(payload))
      .then((res) => {
        const { payload: { data: { scheduleId } } } = res;

        console.log({ scheduleId });

        history.goBack();
      })
      .catch((err) => {
        console.log(err);
      });

    console.log({ payload });
  }

  useEffect(() => {
    if (!templates) {
      setData(scheduleData());
    } else {
      const schedule = templates.find(({ scheduleId }) => `${scheduleId}` === template_id);
      const { scheduleItems } = schedule || {};
      const create = scheduleItems ? scheduleItems.map(({
        scheduleItemDayEnd, scheduleItemDayStart, scheduleItemTimeEnd, scheduleItemTimeStart,
      }) => ({
        scheduleItemDayEnd, scheduleItemDayStart, scheduleItemTimeEnd, scheduleItemTimeStart,
      })) : [];

      console.log('template_id', create);

      setData(scheduleData(create));
    }

    setSchedules(newScheduleData());

  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  if (!schedules || schedules.length === 0) return null;

  return (
    <div className={styles.messageBox}>
      <div className={styles.screeningHead}>
        <Link to="/" className={styles.backTxt}>
          {'< Tillbaka'}
        </Link>
      </div>
      <Schedules
        schedules={schedules}
        set={set}
        data={data}
        slug={'create'}
      />
      <button className={styles.btnReactivate} onClick={create}>
        Spara & aktivera
      </button>
    </div>
  );
};

export default Create;

