import moment from 'moment';
import { useEffect } from 'react';
import styles from './Schedule.module.css';

import Schedules from './Schedules';

const Inactive = () => <p>Det finns inget kommande schema.</p>;

const Upcoming = ({ clientID, refresh, update, schedules }) => {
  const data = schedules.filter(({ scheduleStart }) =>
    moment().isBefore(scheduleStart)
  );

  // eslint-disable-next-line react-hooks/exhaustive-deps
  useEffect(refresh, []);

  return (
    <div className={styles.messageBox}>
      <Schedules
        schedules={data}
        slug={'upcoming'}
        disableInput={true}
        refresh={refresh}
        update={update}
        fallback={Inactive}
        userId={clientID}
      />
    </div>
  );
};

export default Upcoming;
