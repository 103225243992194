import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import { useState, forwardRef, useRef } from 'react';
import ReactDOM from 'react-dom';

export const useActionModal = (Component, mProps, cProps) => {
  const [open, setOpen] = useState(false);
  const [modalProps, setModalProps] = useState(mProps || {});
  const [componentProps, setComponentProps] = useState(cProps || {});
  const Child = mProps.useImperativeHandle ? forwardRef(Component) : Component;
  const childRef = useRef(null);

  const handleOpen = (mProps = {}, cProps = {}) => {
    setModalProps({...modalProps, ...mProps});
    setComponentProps({...componentProps, ...cProps});
    setOpen(true);
  };

  const handleClose = (event, reason) => {
    if (modalProps.disableAutoClose) {
      return;
    }
    setOpen(false);
  };

  const handleCancel = async () => {
    if (childRef.current.onCancel) {
      await childRef.current.onCancel();
    }
    setOpen(false);
    modalProps.onCancel && modalProps.onCancel();
  }

  const handleOk = (close = true) => {
    setOpen(!close);
    modalProps.onSuccess && modalProps.onSuccess();
  };

  return {
    open: (mProps, cProps) => handleOpen(mProps, cProps),
    close: () => setOpen(false),
    modal: ReactDOM.createPortal(
      <Dialog open={open} onClose={handleClose}>
        {modalProps.title && <DialogTitle>{modalProps.title}</DialogTitle>}
        <DialogContent>
          <Child ref={childRef} id={'modal-form'} {...componentProps} onSuccess={handleOk}></Child>
        </DialogContent>
        <DialogActions style={{padding: '25px 24px'}}>
          {modalProps.onCancel && <Button onClick={handleCancel}>{modalProps.cancelText}</Button>}
          <Button  variant="contained" form='modal-form' type="submit">{modalProps.okText}</Button>
        </DialogActions>
      </Dialog>,
      document.body
    ),
  };
};
