import { createSlice } from '@reduxjs/toolkit';
import axios from 'axios';

export const therapistUsersSlice = createSlice({
    name: 'therapistUsers',
    initialState: {
      list: {userId: 49},
    },
    reducers: {
      therapistLogout : state => {
          state.list = {};
      },
      userList : (state, action) => {
        state.list = action.payload;
      }
    },
  });
  
  export const { therapistLogout, userList } = therapistUsersSlice.actions;
  
  // The function below is called a thunk and allows us to perform async logic. It
  // can be dispatched like a regular action: `dispatch(incrementAsync(10))`. This
  // will call the thunk with the `dispatch` function as the first argument. Async
  // code can then be executed and other actions can be dispatched
  /*
  export const incrementAsync = amount => dispatch => {
    setTimeout(() => {
      dispatch(incrementByAmount(amount));
    }, 1000);
  };
  */
  // The function below is called a selector and allows us to select a value from
  // the state. Selectors can also be defined inline where they're used instead of
  // in the slice file. For example: `useSelector((state) => state.counter.value)`
  export const therapistUsersList = state => state.therapistUsers.list;
  
  export default therapistUsersSlice.reducer;

  export const therapistUsers = () => async dispatch => {
    try {
        const res = await axios('/api/user?userid=0');
        //const res = await Axios('/api/therapist/therapistUsers')
        //setTableData(res.data);
        dispatch(userList(res.data))
        console.log('--inside--slice');
    } catch(err) {
        console.log(err)
    }
  }