import React from 'react';
import MeasureItem from './MeasureItem';
import styles from './MeasureItem.module.css';

const MeasureGrid = ({ BreathalizerResult: results }) => {
  return (
    <section className={styles.cards}>
      {results &&
        results.length > 0 &&
        results.map((item) => <MeasureItem key={item.resultId} {...item} />)}
    </section>
  );
};

export default MeasureGrid;
