import React from 'react';
import { useSelector } from 'react-redux';

import Schedule from './Schedule';
import styles from './Schedule.module.css'

const noop = () => {};

const Inactive = () => {
  const pro = useSelector(({ user: { pro }}) => pro);
  const label = pro ? 'patienten' : 'Klienten';

  return (
    <p>
      <strong>(!) Inget aktivt blåsschema </strong>
      -
      {label} kan fortfarande genomföra blåstester men får inga påminnelser eller registreringar av missade blås
    </p>
  );
};

const Schedules = ({
  schedules = [],
  data = {},
  set,
  disableInput = false,
  slug,
  refresh,
  update,
  fallback: Fallback,
  userId,
  setErr,
  err,
}) => {
  if (!schedules || schedules.length === 0) {
    if (Fallback) return (<Fallback />);
    return (<Inactive />);
  }

  return (
    <ul className={styles.sul}>
      {
        schedules
        && schedules.length > 0
        && schedules.map((props, index) => {
          let modified = false;
          if (data[props.scheduleId]) modified = true;

          return (
            <Schedule
              {...props}
              key={props.scheduleId}
              data={data}
              set={set || noop}
              modified={modified}
              index={index}
              slug={slug}
              disableInput={disableInput}
              refresh={refresh}
              update={update}
              userId={userId}
              setErr={setErr}
              err={err}
            />
          );
        })
      }
    </ul>
  );
};

export default Schedules;
