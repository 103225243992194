import { configureStore } from '@reduxjs/toolkit';
import axios from 'axios';
import { combineReducers } from 'redux';
import axiosMiddleware from 'redux-axios-middleware';
import {
  FLUSH,
  PAUSE,
  PERSIST,
  persistReducer,
  persistStore,
  PURGE,
  REGISTER,
  REHYDRATE
} from 'redux-persist';
import storage from 'redux-persist/lib/storage';

import patientReducer from '../redux/patient';
import therapistReducer from '../redux/therapistSlice';
import therapistUsersReducer from '../redux/therapistUsersSlice';
import userReducer, { logout } from '../redux/user';

const authPersistConfig = {
  key: 'auth',
  storage,
  whitelist: ['loggedIn', 'token'],
};

const reducer = combineReducers({
  user: persistReducer(authPersistConfig, userReducer),
  therapist: therapistReducer,
  patient: patientReducer,
  therapistUsers: therapistUsersReducer,
});

const crasapi = axios.create({
  //all axios can be used, shown in axios documentation
  baseURL: '/api/',
  responseType: 'json',
});

const middlewareConfig = {
  interceptors: {
    request: [
      function ({ getState }, config) {
        const { user } = getState() || {};
        if (user.token) {
          config.headers['Authorization'] = `Bearer ${user.token}`;
        }
        return config;
      },
    ],
    response: [
      {
        error: function ({ getState, dispatch, getSourceAction }, error) {
          if (error?.response?.status === 401) {
            dispatch(logout());
          }
          return Promise.reject(error)
        },
      },
    ],
  },
};

export const store = configureStore({
  reducer,
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware({
      serializableCheck: {
        ignoredActions: [FLUSH, REHYDRATE, PAUSE, PERSIST, PURGE, REGISTER],
      },
    }).concat(axiosMiddleware(crasapi, middlewareConfig)),
});

export const persistor = persistStore(store);
