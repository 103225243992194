import React from 'react'
import styles from './ModuleCSS/Support.module.css';

function Support() {

  return (
    <div className={styles.container}>
      <h2>Hur kan vi hjälpa till?</h2>
      <p>Albus support finns tillgängliga på vardagar 8-17</p>
      <div className={styles.wrapper}>
        <div className={styles.support}>
          <p className={styles.supportTxtHead}>Telefon</p>
          <p className={styles.supportTxtCont}>08-23 02 22</p>
        </div>
        <div className={styles.support}>
          <p className={styles.supportTxtHead}>E-mail</p>
          <a href="mailto:info@albus.care">
            <p className={styles.supportTxtCont}>info@albus.care</p>
          </a>
        </div>
      </div>

      <div className={styles.returnB}>
        <h3>Retur av mätare</h3>
        <p>En alkomätare ska vara returnerad inom 30 dagar efter att en klient avslutats. Vid utebliven retur
          av eller förstörd hårdvara tillkommer kostnader (exklusive moms) enligt nedan:</p>
        <ul>
          <li><span className={styles.highlight}>Utebliven/förstörd alkomätare:  </span>1 000 kr</li>
          <li><span className={styles.highlight}>Utebliven/förstörd laddsladd (mini-USB): </span>150 kr</li>
          <li><span className={styles.highlight}>Uteblivet/förstört fodral: </span>50 kr</li>
        </ul>
        <ul>
          <li className={styles.highlight}>Returadress</li>
          <li>Albus Care AB</li>
          <li>Vintervägen 42</li>
          <li>824 51</li>
          <li>Hudiksvall</li>
        </ul>
      </div>

      <div className={styles.wrapperAction}>
        <a className={styles.link} href="https://www.dropbox.com/s/fm34hlit726lbs6/CRAS_Instruktionsmanual_iOS_2019.pdf?dl=1" target="_blank" rel="noopener noreferrer">Manual för iPhone</a>
        <a className={styles.link} href="https://www.dropbox.com/s/y7lrvephf2gxtnd/CRAS_Instruktionsmanual_Android_2019.pdf?dl=1" target="_blank" rel="noopener noreferrer">Manual för Android</a>
        <a className={styles.link} href="https://www.esante.se/sekretess-integritet" rel="noreferrer" target="_blank">Sekretess- & Integritetspolicy</a>
        <a className={styles.link} href="https://www.esante.se/allm%C3%A4nna-villkor" rel="noreferrer" target="_blank">Allmänna villkor</a>
      </div>
    </div>
  )
}

export default Support
