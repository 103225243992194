import Box from '@mui/material/Box';
import TextField from '@mui/material/TextField';
import { LocalizationProvider } from '@mui/x-date-pickers-pro';
import { AdapterDayjs } from '@mui/x-date-pickers-pro/AdapterDayjs';
import { DateRangePicker } from '@mui/x-date-pickers-pro/DateRangePicker';
import React, { useEffect } from 'react';

export default function DateRangeFilter({ value: propsValue, onChange }) {
  const [value, setValue] = React.useState([null, null]);

  useEffect(() => {
    setValue([propsValue[0], propsValue[1]]);
  }, [propsValue]);

  return (
    <LocalizationProvider
      dateAdapter={AdapterDayjs}
      localeText={{ start: 'Från datum', end: ' Till datum' }}
    >
      <DateRangePicker
        value={value}
        maxDate={new Date()}
        onChange={(newValue) => {
          setValue(newValue);
          onChange && onChange(newValue);
        }}
        renderInput={(startProps, endProps) => (
          <React.Fragment>
            <TextField {...startProps} size="small" />
            <Box sx={{ mx: 2 }}></Box>
            <TextField {...endProps} size="small" />
          </React.Fragment>
        )}
      />
    </LocalizationProvider>
  );
}
