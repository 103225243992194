import moment from 'moment';
import { useEffect } from 'react';
import Icon from './Icon';
import styles from './Schedule.module.css';

const Input = ({ onChange, className, value, disabled, placeholder }) => (
  <input
    type="text"
    onChange={onChange}
    className={className}
    value={value}
    disabled={disabled}
    maxLength="30"
    placeholder={placeholder}
  />
);

const ScheduleHead = ({
  start,
  stop,
  label,
  slug,
  sname,
  disabled,
  setSName,
  active,
  upcoming,
}) => {
  const create = slug === 'create';
  const diff = -(active
    ? moment().diff(stop, 'days')
    : moment().diff(start, 'days'));

  let text = '';

  if (upcoming) text = `(Aktiveras om ${diff} dagar)`;
  if (!upcoming) text = `(Löper ut om ${diff} dagar)`;
  if (diff === 0) text = 'Inaktiveras imorgon';
  if (diff < 0) text = 'Inaktiverat';

  if (!upcoming && diff > 30) text = '';

  const handleInput = ({ target: { value } }) => setSName(value);

  const nameSchedule = () => {
    if (sname === '') setSName(label);
  };

  useEffect(() => {
    nameSchedule();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <div className={styles.schemCircle}>
      {create ? (
        <Input
          onChange={handleInput}
          className={styles.inputBorder}
          value={sname}
          disabled={disabled}
          placeholder="Ange schemanamn"
        />
      ) : (
        <>
          <Icon active={active} upcoming={upcoming} />
          <Input
            onChange={handleInput}
            className={styles.inputShow}
            value={sname}
            disabled={disabled}
            maxLength="30"
          />
          <p className={styles.daysleft}>{text}</p>
        </>
      )}
    </div>
  );
};

export default ScheduleHead;
