import { useActionModal } from '@components/shared';
import { getTherapistTransfer } from '@redux/user-transfer';
import { useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { PatientTranserConfirm } from '../patient-transfer-confirm/patient-transfer-confirm';

export const PatientTransferInfo = () => {
  const dispatch = useDispatch();
  const { open, modal } = useActionModal(PatientTranserConfirm, {
    title: 'Flytta till annan admin',
    okText: 'Acceptera',
    cancelText: 'Neka',
    disableAutoClose: true,
    useImperativeHandle: true
  });

  const updatePatientTransferStatus = async (transfer) => {
    return new Promise((resolve, reject) => {
      open(
        {
          onCancel: () => {
            console.log('cancel');
            resolve();
          },
          onSuccess: () => {
            resolve();
          },
        },
        { transfer }
      );
    });
  };

  const handleTransfers = async (transfers) => {
    for (let transfer of transfers) {
      if (transfer.status === 'request') {
        await updatePatientTransferStatus(transfer);
      } else if (transfer.status === 'accept') {
        alert(`Klient ${transfer.patientAlbusId} har nu flyttats till ${transfer.toTherapistName}.`)
      } else if (transfer.status === 'reject') {
        alert(`${transfer.toTherapistName} har nekat din förfrågan om flytt av klient ${transfer.patientAlbusId}. Kontakta administratören för att få reda på varför.`)
      }
    }
  };

  useEffect(() => {
    dispatch(getTherapistTransfer()).then((result) => {
      console.log('transfers', result.payload.data.transfers);
      const transfers = result?.payload?.data?.transfers || [];
      handleTransfers(transfers);
    });
  }, []);

  return <>{modal}</>;
};
