import React, { useEffect } from 'react';
import DateRangeFilter from './DateRangeFilter';

function SignalsFilter({ value: propsValue, onChange }) {
  const [value, setValue] = React.useState({startTime: null, endTime: null});

  useEffect(() => {
    setValue(propsValue || {startTime: null, endTime: null});
  }, [propsValue]);

  useEffect(() => {
    onChange && onChange(value);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [value]);

  return (
    <div>
      <DateRangeFilter
        value={[value.startTime, value.endTime]}
        onChange={(value) => {
          setValue({
            startTime: value[0],
            endTime: value[1],
          });
        }}
      ></DateRangeFilter>
    </div>
  );
}

export default SignalsFilter;
